export const COLLECT_BILLING_INFORMATION = false;

export const parseBool = (val) => {
  if (val === 'false') {
    return false;
  }
  return Boolean(val);
};

export const configItem = (storage, key, defaultValue = '') => {
  return storage.getItem(`ruc:configuration:${key}`) || defaultValue;
};

export const systemBillingFeatures = (storage) => {
  return {
    persist: parseBool(configItem(storage, 'PAYMENTS_PERSIST', 'false')),
    registration: parseBool(configItem(storage, 'PAYMENTS_REGISTRATION', 'false')),
    provider: configItem(storage, 'PAYMENTS_PROVIDER', ''),
    allowed: parseBool(configItem(storage, 'PAYMENTS_ALLOWED', 'false')),
  };
};

export const tripPaymentStatus = (tripSegments) => {
  const segmentStatuses = tripSegments.map(({ paymentStatus }) => paymentStatus);
  if (segmentStatuses.includes('failed')) {
    return 'failed';
  }

  const paidSegments = segmentStatuses.filter((status) => status === 'paid');
  if (paidSegments.length === tripSegments.length) {
    return 'paid in full';
  }

  // If there is only one trip segment then it is binary and cannot be partial.
  if (tripSegments.length > 1 && paidSegments.length < tripSegments.length) {
    return 'paid in part';
  }

  return 'pending';
};
