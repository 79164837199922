/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const tripRating = /* GraphQL */ `
  mutation TripRating($input: TripRatingRequest!) {
    tripRating(input: $input) {
      message
      trips {
        id
        recordId
        recordTripId
        mroDeviceSerialNumber
        vehicleVin
        tsStart
        tsEnd
        polyline
        distMro
        odoMro
        distGps
        distRoute
        fuel
        odoGps
        odoMroStart
        odoMroEnd
        fuelStart
        fuelEnd
        fuelAdded
        totalFuel
        username
        vehicleId
        pilotProgramId
        mroId
        createdAt
        processStatus
        processMsg
        processedAt
        logs
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        reviewStatus
        reviewReason
        notes
        updatedAt
      }
    }
  }
`;
export const eventMessage = /* GraphQL */ `
  mutation EventMessage($input: EventMessageRequest!) {
    eventMessage(input: $input) {
      message
    }
  }
`;
export const tripArchive = /* GraphQL */ `
  mutation TripArchive($input: TripArchiveRequest!) {
    tripArchive(input: $input) {
      message
    }
  }
`;
export const vinReport = /* GraphQL */ `
  mutation VinReport($input: VinReportRequest!) {
    vinReport(input: $input) {
      message
    }
  }
`;
export const generateStatements = /* GraphQL */ `
  mutation GenerateStatements($input: GenerateStatementsRequest!) {
    generateStatements(input: $input) {
      message
    }
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail($input: SendEmailRequest!) {
    sendEmail(input: $input) {
      message
    }
  }
`;
export const agreeToTerms = /* GraphQL */ `
  mutation AgreeToTerms($input: AgreeToTermsRequest!) {
    agreeToTerms(input: $input) {
      message
    }
  }
`;
export const bulkImportMroDevices = /* GraphQL */ `
  mutation BulkImportMroDevices($input: BulkImportMroDevicesRequest!) {
    bulkImportMroDevices(input: $input) {
      errors {
        message
        username
        mroSerialNumber
        vehicleId
      }
      notifications {
        message
        username
        mroSerialNumber
      }
      registrations
      duplicates
    }
  }
`;
export const createConfiguration = /* GraphQL */ `
  mutation CreateConfiguration(
    $input: CreateConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    createConfiguration(input: $input, condition: $condition) {
      key
      name
      description
      value
      options {
        name
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateConfiguration = /* GraphQL */ `
  mutation UpdateConfiguration(
    $input: UpdateConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    updateConfiguration(input: $input, condition: $condition) {
      key
      name
      description
      value
      options {
        name
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteConfiguration = /* GraphQL */ `
  mutation DeleteConfiguration(
    $input: DeleteConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    deleteConfiguration(input: $input, condition: $condition) {
      key
      name
      description
      value
      options {
        name
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCordon = /* GraphQL */ `
  mutation CreateCordon(
    $input: CreateCordonInput!
    $condition: ModelCordonConditionInput
  ) {
    createCordon(input: $input, condition: $condition) {
      id
      state
      name
      description
      zones {
        name
        description
        color
        polyline
      }
      entryFeeCents
      exitFeeCents
      centsPerMileageUnit
      days
      internalStart
      internalEnd
      minimumDistanceInMileageUnit
      minimumTotalDistanceInMileageUnit
      createdAt
      updatedAt
    }
  }
`;
export const updateCordon = /* GraphQL */ `
  mutation UpdateCordon(
    $input: UpdateCordonInput!
    $condition: ModelCordonConditionInput
  ) {
    updateCordon(input: $input, condition: $condition) {
      id
      state
      name
      description
      zones {
        name
        description
        color
        polyline
      }
      entryFeeCents
      exitFeeCents
      centsPerMileageUnit
      days
      internalStart
      internalEnd
      minimumDistanceInMileageUnit
      minimumTotalDistanceInMileageUnit
      createdAt
      updatedAt
    }
  }
`;
export const deleteCordon = /* GraphQL */ `
  mutation DeleteCordon(
    $input: DeleteCordonInput!
    $condition: ModelCordonConditionInput
  ) {
    deleteCordon(input: $input, condition: $condition) {
      id
      state
      name
      description
      zones {
        name
        description
        color
        polyline
      }
      entryFeeCents
      exitFeeCents
      centsPerMileageUnit
      days
      internalStart
      internalEnd
      minimumDistanceInMileageUnit
      minimumTotalDistanceInMileageUnit
      createdAt
      updatedAt
    }
  }
`;
export const createEpaVehicle = /* GraphQL */ `
  mutation CreateEpaVehicle(
    $input: CreateEpaVehicleInput!
    $condition: ModelEpaVehicleConditionInput
  ) {
    createEpaVehicle(input: $input, condition: $condition) {
      id
      make
      model
      year
      cylinders
      trany
      fuelType
      fuelType1
      fuelType2
      mpgCombined
      mpgCity
      mpgHighway
      kplCombined
      kplCity
      kplHighway
      createdAt
      updatedAt
    }
  }
`;
export const updateEpaVehicle = /* GraphQL */ `
  mutation UpdateEpaVehicle(
    $input: UpdateEpaVehicleInput!
    $condition: ModelEpaVehicleConditionInput
  ) {
    updateEpaVehicle(input: $input, condition: $condition) {
      id
      make
      model
      year
      cylinders
      trany
      fuelType
      fuelType1
      fuelType2
      mpgCombined
      mpgCity
      mpgHighway
      kplCombined
      kplCity
      kplHighway
      createdAt
      updatedAt
    }
  }
`;
export const deleteEpaVehicle = /* GraphQL */ `
  mutation DeleteEpaVehicle(
    $input: DeleteEpaVehicleInput!
    $condition: ModelEpaVehicleConditionInput
  ) {
    deleteEpaVehicle(input: $input, condition: $condition) {
      id
      make
      model
      year
      cylinders
      trany
      fuelType
      fuelType1
      fuelType2
      mpgCombined
      mpgCity
      mpgHighway
      kplCombined
      kplCity
      kplHighway
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      key
      timestamp
      username
      updatedBy
      eventId
      eventName
      diff {
        key
        old
        new
      }
      note
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      key
      timestamp
      username
      updatedBy
      eventId
      eventName
      diff {
        key
        old
        new
      }
      note
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      key
      timestamp
      username
      updatedBy
      eventId
      eventName
      diff {
        key
        old
        new
      }
      note
      createdAt
      updatedAt
    }
  }
`;
export const createMROEvent = /* GraphQL */ `
  mutation CreateMROEvent(
    $input: CreateMROEventInput!
    $condition: ModelMROEventConditionInput
  ) {
    createMROEvent(input: $input, condition: $condition) {
      id
      mroDeviceSerialNumber
      username
      vehiceId
      code
      odometerReadingStart
      odometerReading
      mileage
      flagged
      tripId
      type
      description
      dateTime
      source
      isProcessed
      processLogs
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMROEvent = /* GraphQL */ `
  mutation UpdateMROEvent(
    $input: UpdateMROEventInput!
    $condition: ModelMROEventConditionInput
  ) {
    updateMROEvent(input: $input, condition: $condition) {
      id
      mroDeviceSerialNumber
      username
      vehiceId
      code
      odometerReadingStart
      odometerReading
      mileage
      flagged
      tripId
      type
      description
      dateTime
      source
      isProcessed
      processLogs
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMROEvent = /* GraphQL */ `
  mutation DeleteMROEvent(
    $input: DeleteMROEventInput!
    $condition: ModelMROEventConditionInput
  ) {
    deleteMROEvent(input: $input, condition: $condition) {
      id
      mroDeviceSerialNumber
      username
      vehiceId
      code
      odometerReadingStart
      odometerReading
      mileage
      flagged
      tripId
      type
      description
      dateTime
      source
      isProcessed
      processLogs
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMRORate = /* GraphQL */ `
  mutation CreateMRORate(
    $input: CreateMRORateInput!
    $condition: ModelMRORateConditionInput
  ) {
    createMRORate(input: $input, condition: $condition) {
      mroType
      state
      centsPerMileageUnit
      centsPerFuelUnit
      createdAt
      updatedAt
    }
  }
`;
export const updateMRORate = /* GraphQL */ `
  mutation UpdateMRORate(
    $input: UpdateMRORateInput!
    $condition: ModelMRORateConditionInput
  ) {
    updateMRORate(input: $input, condition: $condition) {
      mroType
      state
      centsPerMileageUnit
      centsPerFuelUnit
      createdAt
      updatedAt
    }
  }
`;
export const deleteMRORate = /* GraphQL */ `
  mutation DeleteMRORate(
    $input: DeleteMRORateInput!
    $condition: ModelMRORateConditionInput
  ) {
    deleteMRORate(input: $input, condition: $condition) {
      mroType
      state
      centsPerMileageUnit
      centsPerFuelUnit
      createdAt
      updatedAt
    }
  }
`;
export const createMileageReport = /* GraphQL */ `
  mutation CreateMileageReport(
    $input: CreateMileageReportInput!
    $condition: ModelMileageReportConditionInput
  ) {
    createMileageReport(input: $input, condition: $condition) {
      auditStatus
      id
      odoMileage
      photos {
        mimeType
        size
        storageKey
      }
      reason
      tsReportDate
      updatedBy
      username
      vehicleId
      createdAt
      updatedAt
    }
  }
`;
export const updateMileageReport = /* GraphQL */ `
  mutation UpdateMileageReport(
    $input: UpdateMileageReportInput!
    $condition: ModelMileageReportConditionInput
  ) {
    updateMileageReport(input: $input, condition: $condition) {
      auditStatus
      id
      odoMileage
      photos {
        mimeType
        size
        storageKey
      }
      reason
      tsReportDate
      updatedBy
      username
      vehicleId
      createdAt
      updatedAt
    }
  }
`;
export const deleteMileageReport = /* GraphQL */ `
  mutation DeleteMileageReport(
    $input: DeleteMileageReportInput!
    $condition: ModelMileageReportConditionInput
  ) {
    deleteMileageReport(input: $input, condition: $condition) {
      auditStatus
      id
      odoMileage
      photos {
        mimeType
        size
        storageKey
      }
      reason
      tsReportDate
      updatedBy
      username
      vehicleId
      createdAt
      updatedAt
    }
  }
`;
export const createMileageReportingOption = /* GraphQL */ `
  mutation CreateMileageReportingOption(
    $input: CreateMileageReportingOptionInput!
    $condition: ModelMileageReportingOptionConditionInput
  ) {
    createMileageReportingOption(input: $input, condition: $condition) {
      username
      id
      vehicleId
      deviceSerialNumber
      deviceImei
      manufacturer
      model
      hardwareVersion
      firmwareVersion
      gpsEnabled
      configurableParameters
      shippedDate
      shippingCarrier
      shippingTrackingNumber
      deliveredDate
      activationDate
      firstReportedDate
      lastReportedDate
      vehicle {
        vin
        make
        model
        year
        type
        licensePlate
        registrationState
        isPrimary
        mroType
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        logs
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateMileageReportingOption = /* GraphQL */ `
  mutation UpdateMileageReportingOption(
    $input: UpdateMileageReportingOptionInput!
    $condition: ModelMileageReportingOptionConditionInput
  ) {
    updateMileageReportingOption(input: $input, condition: $condition) {
      username
      id
      vehicleId
      deviceSerialNumber
      deviceImei
      manufacturer
      model
      hardwareVersion
      firmwareVersion
      gpsEnabled
      configurableParameters
      shippedDate
      shippingCarrier
      shippingTrackingNumber
      deliveredDate
      activationDate
      firstReportedDate
      lastReportedDate
      vehicle {
        vin
        make
        model
        year
        type
        licensePlate
        registrationState
        isPrimary
        mroType
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        logs
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteMileageReportingOption = /* GraphQL */ `
  mutation DeleteMileageReportingOption(
    $input: DeleteMileageReportingOptionInput!
    $condition: ModelMileageReportingOptionConditionInput
  ) {
    deleteMileageReportingOption(input: $input, condition: $condition) {
      username
      id
      vehicleId
      deviceSerialNumber
      deviceImei
      manufacturer
      model
      hardwareVersion
      firmwareVersion
      gpsEnabled
      configurableParameters
      shippedDate
      shippingCarrier
      shippingTrackingNumber
      deliveredDate
      activationDate
      firstReportedDate
      lastReportedDate
      vehicle {
        vin
        make
        model
        year
        type
        licensePlate
        registrationState
        isPrimary
        mroType
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        logs
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      username
      type
      templateName
      email
      subject
      text
      html
      shouldBeSentAt
      retries
      status
      statusMsg
      sentAt
      createdBy
      createdAt
      logs
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      username
      type
      templateName
      email
      subject
      text
      html
      shouldBeSentAt
      retries
      status
      statusMsg
      sentAt
      createdBy
      createdAt
      logs
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      username
      type
      templateName
      email
      subject
      text
      html
      shouldBeSentAt
      retries
      status
      statusMsg
      sentAt
      createdBy
      createdAt
      logs
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createParticipant = /* GraphQL */ `
  mutation CreateParticipant(
    $input: CreateParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    createParticipant(input: $input, condition: $condition) {
      address {
        address1
        address2
        city
        state
        postalCode
        extendedPostalCode
      }
      ageGroup
      closedDate
      closedReason
      closedBy
      createdBy
      email
      firstName
      gender
      governmentAffiliation
      isVIP
      lastName
      middleName
      mroDataProviderPreference
      mroDevicePreference
      mroManualCapture
      onboardedDate
      participantPilotProgramId
      phoneNumber
      pilotProgram {
        id
        category
        name
        shortName
        isDeviceRequired
        isGpsRequired
        description
        createdAt
        updatedAt
      }
      preferredContactType
      roadChargeCreditCents
      status
      sumFuelTaxCreditCents
      sumMileageUserFeeCents
      updatedBy
      username
      vehicles {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateParticipant = /* GraphQL */ `
  mutation UpdateParticipant(
    $input: UpdateParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    updateParticipant(input: $input, condition: $condition) {
      address {
        address1
        address2
        city
        state
        postalCode
        extendedPostalCode
      }
      ageGroup
      closedDate
      closedReason
      closedBy
      createdBy
      email
      firstName
      gender
      governmentAffiliation
      isVIP
      lastName
      middleName
      mroDataProviderPreference
      mroDevicePreference
      mroManualCapture
      onboardedDate
      participantPilotProgramId
      phoneNumber
      pilotProgram {
        id
        category
        name
        shortName
        isDeviceRequired
        isGpsRequired
        description
        createdAt
        updatedAt
      }
      preferredContactType
      roadChargeCreditCents
      status
      sumFuelTaxCreditCents
      sumMileageUserFeeCents
      updatedBy
      username
      vehicles {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteParticipant = /* GraphQL */ `
  mutation DeleteParticipant(
    $input: DeleteParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    deleteParticipant(input: $input, condition: $condition) {
      address {
        address1
        address2
        city
        state
        postalCode
        extendedPostalCode
      }
      ageGroup
      closedDate
      closedReason
      closedBy
      createdBy
      email
      firstName
      gender
      governmentAffiliation
      isVIP
      lastName
      middleName
      mroDataProviderPreference
      mroDevicePreference
      mroManualCapture
      onboardedDate
      participantPilotProgramId
      phoneNumber
      pilotProgram {
        id
        category
        name
        shortName
        isDeviceRequired
        isGpsRequired
        description
        createdAt
        updatedAt
      }
      preferredContactType
      roadChargeCreditCents
      status
      sumFuelTaxCreditCents
      sumMileageUserFeeCents
      updatedBy
      username
      vehicles {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createParticipantConnection = /* GraphQL */ `
  mutation CreateParticipantConnection(
    $input: CreateParticipantConnectionInput!
    $condition: ModelParticipantConnectionConditionInput
  ) {
    createParticipantConnection(input: $input, condition: $condition) {
      accessToken
      authorizationCode
      externalVehicleId
      refreshToken
      resourceProvider
      id
      username
      vehicleId
      createdAt
      updatedAt
      forceRestoreConnection
    }
  }
`;
export const updateParticipantConnection = /* GraphQL */ `
  mutation UpdateParticipantConnection(
    $input: UpdateParticipantConnectionInput!
    $condition: ModelParticipantConnectionConditionInput
  ) {
    updateParticipantConnection(input: $input, condition: $condition) {
      accessToken
      authorizationCode
      externalVehicleId
      refreshToken
      resourceProvider
      id
      username
      vehicleId
      createdAt
      updatedAt
      forceRestoreConnection
    }
  }
`;
export const deleteParticipantConnection = /* GraphQL */ `
  mutation DeleteParticipantConnection(
    $input: DeleteParticipantConnectionInput!
    $condition: ModelParticipantConnectionConditionInput
  ) {
    deleteParticipantConnection(input: $input, condition: $condition) {
      accessToken
      authorizationCode
      externalVehicleId
      refreshToken
      resourceProvider
      id
      username
      vehicleId
      createdAt
      updatedAt
      forceRestoreConnection
    }
  }
`;
export const createParticipantInquiry = /* GraphQL */ `
  mutation CreateParticipantInquiry(
    $input: CreateParticipantInquiryInput!
    $condition: ModelParticipantInquiryConditionInput
  ) {
    createParticipantInquiry(input: $input, condition: $condition) {
      id
      username
      createdAt
      createdBy
      updatedAt
      updatedBy
      assignee
      category
      subject
      body
      status
      name
      email
      emailOriginalMessageId
      emailS3Key
      bodyHtml
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      comments {
        nextToken
      }
    }
  }
`;
export const updateParticipantInquiry = /* GraphQL */ `
  mutation UpdateParticipantInquiry(
    $input: UpdateParticipantInquiryInput!
    $condition: ModelParticipantInquiryConditionInput
  ) {
    updateParticipantInquiry(input: $input, condition: $condition) {
      id
      username
      createdAt
      createdBy
      updatedAt
      updatedBy
      assignee
      category
      subject
      body
      status
      name
      email
      emailOriginalMessageId
      emailS3Key
      bodyHtml
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      comments {
        nextToken
      }
    }
  }
`;
export const deleteParticipantInquiry = /* GraphQL */ `
  mutation DeleteParticipantInquiry(
    $input: DeleteParticipantInquiryInput!
    $condition: ModelParticipantInquiryConditionInput
  ) {
    deleteParticipantInquiry(input: $input, condition: $condition) {
      id
      username
      createdAt
      createdBy
      updatedAt
      updatedBy
      assignee
      category
      subject
      body
      status
      name
      email
      emailOriginalMessageId
      emailS3Key
      bodyHtml
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      comments {
        nextToken
      }
    }
  }
`;
export const createParticipantInquiryComment = /* GraphQL */ `
  mutation CreateParticipantInquiryComment(
    $input: CreateParticipantInquiryCommentInput!
    $condition: ModelParticipantInquiryCommentConditionInput
  ) {
    createParticipantInquiryComment(input: $input, condition: $condition) {
      id
      inquiryId
      username
      author
      body
      type
      status
      bodyHtml
      emailPayload {
        from
        to
        subject
        text
        inReplyTo
        html
      }
      emailS3Key
      emailAttachments {
        s3Key
        filename
        contentType
        size
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateParticipantInquiryComment = /* GraphQL */ `
  mutation UpdateParticipantInquiryComment(
    $input: UpdateParticipantInquiryCommentInput!
    $condition: ModelParticipantInquiryCommentConditionInput
  ) {
    updateParticipantInquiryComment(input: $input, condition: $condition) {
      id
      inquiryId
      username
      author
      body
      type
      status
      bodyHtml
      emailPayload {
        from
        to
        subject
        text
        inReplyTo
        html
      }
      emailS3Key
      emailAttachments {
        s3Key
        filename
        contentType
        size
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteParticipantInquiryComment = /* GraphQL */ `
  mutation DeleteParticipantInquiryComment(
    $input: DeleteParticipantInquiryCommentInput!
    $condition: ModelParticipantInquiryCommentConditionInput
  ) {
    deleteParticipantInquiryComment(input: $input, condition: $condition) {
      id
      inquiryId
      username
      author
      body
      type
      status
      bodyHtml
      emailPayload {
        from
        to
        subject
        text
        inReplyTo
        html
      }
      emailS3Key
      emailAttachments {
        s3Key
        filename
        contentType
        size
      }
      createdAt
      updatedAt
    }
  }
`;
export const createParticipantStatement = /* GraphQL */ `
  mutation CreateParticipantStatement(
    $input: CreateParticipantStatementInput!
    $condition: ModelParticipantStatementConditionInput
  ) {
    createParticipantStatement(input: $input, condition: $condition) {
      id
      username
      month
      periodFrom
      periodTo
      filename
      mileage
      fuel
      mbufFee
      cordonFee
      todFee
      tollFee
      fuelTaxCredit
      balance
      trips
      note
      createdBy
      shouldSendEmailToParticipant
      createdAt
      updatedAt
    }
  }
`;
export const updateParticipantStatement = /* GraphQL */ `
  mutation UpdateParticipantStatement(
    $input: UpdateParticipantStatementInput!
    $condition: ModelParticipantStatementConditionInput
  ) {
    updateParticipantStatement(input: $input, condition: $condition) {
      id
      username
      month
      periodFrom
      periodTo
      filename
      mileage
      fuel
      mbufFee
      cordonFee
      todFee
      tollFee
      fuelTaxCredit
      balance
      trips
      note
      createdBy
      shouldSendEmailToParticipant
      createdAt
      updatedAt
    }
  }
`;
export const deleteParticipantStatement = /* GraphQL */ `
  mutation DeleteParticipantStatement(
    $input: DeleteParticipantStatementInput!
    $condition: ModelParticipantStatementConditionInput
  ) {
    deleteParticipantStatement(input: $input, condition: $condition) {
      id
      username
      month
      periodFrom
      periodTo
      filename
      mileage
      fuel
      mbufFee
      cordonFee
      todFee
      tollFee
      fuelTaxCredit
      balance
      trips
      note
      createdBy
      shouldSendEmailToParticipant
      createdAt
      updatedAt
    }
  }
`;
export const createPaymentCard = /* GraphQL */ `
  mutation CreatePaymentCard(
    $input: CreatePaymentCardInput!
    $condition: ModelPaymentCardConditionInput
  ) {
    createPaymentCard(input: $input, condition: $condition) {
      alias
      billingCountryCode
      billingZip
      brand
      createdAt
      createdBy
      expirationMonth
      expirationYear
      externalId
      fingerprint
      id
      isDefault
      last4
      nameOnCard
      serviceProviderKey
      token
      updatedAt
      updatedBy
      username
    }
  }
`;
export const updatePaymentCard = /* GraphQL */ `
  mutation UpdatePaymentCard(
    $input: UpdatePaymentCardInput!
    $condition: ModelPaymentCardConditionInput
  ) {
    updatePaymentCard(input: $input, condition: $condition) {
      alias
      billingCountryCode
      billingZip
      brand
      createdAt
      createdBy
      expirationMonth
      expirationYear
      externalId
      fingerprint
      id
      isDefault
      last4
      nameOnCard
      serviceProviderKey
      token
      updatedAt
      updatedBy
      username
    }
  }
`;
export const deletePaymentCard = /* GraphQL */ `
  mutation DeletePaymentCard(
    $input: DeletePaymentCardInput!
    $condition: ModelPaymentCardConditionInput
  ) {
    deletePaymentCard(input: $input, condition: $condition) {
      alias
      billingCountryCode
      billingZip
      brand
      createdAt
      createdBy
      expirationMonth
      expirationYear
      externalId
      fingerprint
      id
      isDefault
      last4
      nameOnCard
      serviceProviderKey
      token
      updatedAt
      updatedBy
      username
    }
  }
`;
export const createPilotProgram = /* GraphQL */ `
  mutation CreatePilotProgram(
    $input: CreatePilotProgramInput!
    $condition: ModelPilotProgramConditionInput
  ) {
    createPilotProgram(input: $input, condition: $condition) {
      id
      category
      name
      shortName
      isDeviceRequired
      isGpsRequired
      description
      details {
        participantsSelected
        assetsAndRoadScope
        participantStatement
        minimumDataRequired
        locationGPS
      }
      participants {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePilotProgram = /* GraphQL */ `
  mutation UpdatePilotProgram(
    $input: UpdatePilotProgramInput!
    $condition: ModelPilotProgramConditionInput
  ) {
    updatePilotProgram(input: $input, condition: $condition) {
      id
      category
      name
      shortName
      isDeviceRequired
      isGpsRequired
      description
      details {
        participantsSelected
        assetsAndRoadScope
        participantStatement
        minimumDataRequired
        locationGPS
      }
      participants {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePilotProgram = /* GraphQL */ `
  mutation DeletePilotProgram(
    $input: DeletePilotProgramInput!
    $condition: ModelPilotProgramConditionInput
  ) {
    deletePilotProgram(input: $input, condition: $condition) {
      id
      category
      name
      shortName
      isDeviceRequired
      isGpsRequired
      description
      details {
        participantsSelected
        assetsAndRoadScope
        participantStatement
        minimumDataRequired
        locationGPS
      }
      participants {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTod = /* GraphQL */ `
  mutation CreateTod(
    $input: CreateTodInput!
    $condition: ModelTodConditionInput
  ) {
    createTod(input: $input, condition: $condition) {
      id
      name
      days
      internalStart
      internalEnd
      centsPerMileageUnit
      createdAt
      updatedAt
    }
  }
`;
export const updateTod = /* GraphQL */ `
  mutation UpdateTod(
    $input: UpdateTodInput!
    $condition: ModelTodConditionInput
  ) {
    updateTod(input: $input, condition: $condition) {
      id
      name
      days
      internalStart
      internalEnd
      centsPerMileageUnit
      createdAt
      updatedAt
    }
  }
`;
export const deleteTod = /* GraphQL */ `
  mutation DeleteTod(
    $input: DeleteTodInput!
    $condition: ModelTodConditionInput
  ) {
    deleteTod(input: $input, condition: $condition) {
      id
      name
      days
      internalStart
      internalEnd
      centsPerMileageUnit
      createdAt
      updatedAt
    }
  }
`;
export const createToll = /* GraphQL */ `
  mutation CreateToll(
    $input: CreateTollInput!
    $condition: ModelTollConditionInput
  ) {
    createToll(input: $input, condition: $condition) {
      id
      state
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateToll = /* GraphQL */ `
  mutation UpdateToll(
    $input: UpdateTollInput!
    $condition: ModelTollConditionInput
  ) {
    updateToll(input: $input, condition: $condition) {
      id
      state
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteToll = /* GraphQL */ `
  mutation DeleteToll(
    $input: DeleteTollInput!
    $condition: ModelTollConditionInput
  ) {
    deleteToll(input: $input, condition: $condition) {
      id
      state
      name
      createdAt
      updatedAt
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      username
      createdAt
      createdBy
      updatedAt
      paymentProcessorResponse {
        referenceNumber
        transactionResponse
      }
      description
      type
      status
      retries
      subtotalCents
      discountCents
      amountCents
      paymentMethodId
      tripSegments {
        nextToken
      }
      tripAdjustments {
        nextToken
      }
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      username
      createdAt
      createdBy
      updatedAt
      paymentProcessorResponse {
        referenceNumber
        transactionResponse
      }
      description
      type
      status
      retries
      subtotalCents
      discountCents
      amountCents
      paymentMethodId
      tripSegments {
        nextToken
      }
      tripAdjustments {
        nextToken
      }
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      username
      createdAt
      createdBy
      updatedAt
      paymentProcessorResponse {
        referenceNumber
        transactionResponse
      }
      description
      type
      status
      retries
      subtotalCents
      discountCents
      amountCents
      paymentMethodId
      tripSegments {
        nextToken
      }
      tripAdjustments {
        nextToken
      }
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
    }
  }
`;
export const createTrip = /* GraphQL */ `
  mutation CreateTrip(
    $input: CreateTripInput!
    $condition: ModelTripConditionInput
  ) {
    createTrip(input: $input, condition: $condition) {
      id
      recordId
      recordTripId
      mroDeviceSerialNumber
      vehicleVin
      tsStart
      tsEnd
      polyline
      distMro
      odoMro
      distGps
      distRoute
      fuel
      odoGps
      odoMroStart
      odoMroEnd
      fuelStart
      fuelEnd
      fuelAdded
      totalFuel
      odometerBin {
        odo
        timestamp
      }
      username
      vehicleId
      pilotProgramId
      mroId
      createdAt
      processStatus
      processMsg
      processedAt
      logs
      mileageFeeCents
      fuelFeeCents
      cordonFeeCents
      todFeeCents
      tollFeeCents
      reviewStatus
      reviewReason
      polylines {
        polyline
        description
        createdAt
        createdBy
      }
      adjustments {
        nextToken
      }
      notes
      tripSegments {
        nextToken
      }
      vehicle {
        vin
        make
        model
        year
        type
        licensePlate
        registrationState
        isPrimary
        mroType
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        logs
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateTrip = /* GraphQL */ `
  mutation UpdateTrip(
    $input: UpdateTripInput!
    $condition: ModelTripConditionInput
  ) {
    updateTrip(input: $input, condition: $condition) {
      id
      recordId
      recordTripId
      mroDeviceSerialNumber
      vehicleVin
      tsStart
      tsEnd
      polyline
      distMro
      odoMro
      distGps
      distRoute
      fuel
      odoGps
      odoMroStart
      odoMroEnd
      fuelStart
      fuelEnd
      fuelAdded
      totalFuel
      odometerBin {
        odo
        timestamp
      }
      username
      vehicleId
      pilotProgramId
      mroId
      createdAt
      processStatus
      processMsg
      processedAt
      logs
      mileageFeeCents
      fuelFeeCents
      cordonFeeCents
      todFeeCents
      tollFeeCents
      reviewStatus
      reviewReason
      polylines {
        polyline
        description
        createdAt
        createdBy
      }
      adjustments {
        nextToken
      }
      notes
      tripSegments {
        nextToken
      }
      vehicle {
        vin
        make
        model
        year
        type
        licensePlate
        registrationState
        isPrimary
        mroType
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        logs
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteTrip = /* GraphQL */ `
  mutation DeleteTrip(
    $input: DeleteTripInput!
    $condition: ModelTripConditionInput
  ) {
    deleteTrip(input: $input, condition: $condition) {
      id
      recordId
      recordTripId
      mroDeviceSerialNumber
      vehicleVin
      tsStart
      tsEnd
      polyline
      distMro
      odoMro
      distGps
      distRoute
      fuel
      odoGps
      odoMroStart
      odoMroEnd
      fuelStart
      fuelEnd
      fuelAdded
      totalFuel
      odometerBin {
        odo
        timestamp
      }
      username
      vehicleId
      pilotProgramId
      mroId
      createdAt
      processStatus
      processMsg
      processedAt
      logs
      mileageFeeCents
      fuelFeeCents
      cordonFeeCents
      todFeeCents
      tollFeeCents
      reviewStatus
      reviewReason
      polylines {
        polyline
        description
        createdAt
        createdBy
      }
      adjustments {
        nextToken
      }
      notes
      tripSegments {
        nextToken
      }
      vehicle {
        vin
        make
        model
        year
        type
        licensePlate
        registrationState
        isPrimary
        mroType
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        logs
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createTripAdjustment = /* GraphQL */ `
  mutation CreateTripAdjustment(
    $input: CreateTripAdjustmentInput!
    $condition: ModelTripAdjustmentConditionInput
  ) {
    createTripAdjustment(input: $input, condition: $condition) {
      tripId
      id
      username
      vehicleId
      tripSegmentId
      type
      state
      stateCode
      cordonId
      cordonName
      todId
      todName
      tollId
      adjMileage
      adjMileageFeeCents
      adjFuel
      adjFuelFeeCents
      adjCordonFeeCents
      adjTodFeeCents
      adjTollFeeCents
      note
      content
      createdBy
      createdAt
      transactionId
      paymentStatus
      updatedAt
    }
  }
`;
export const updateTripAdjustment = /* GraphQL */ `
  mutation UpdateTripAdjustment(
    $input: UpdateTripAdjustmentInput!
    $condition: ModelTripAdjustmentConditionInput
  ) {
    updateTripAdjustment(input: $input, condition: $condition) {
      tripId
      id
      username
      vehicleId
      tripSegmentId
      type
      state
      stateCode
      cordonId
      cordonName
      todId
      todName
      tollId
      adjMileage
      adjMileageFeeCents
      adjFuel
      adjFuelFeeCents
      adjCordonFeeCents
      adjTodFeeCents
      adjTollFeeCents
      note
      content
      createdBy
      createdAt
      transactionId
      paymentStatus
      updatedAt
    }
  }
`;
export const deleteTripAdjustment = /* GraphQL */ `
  mutation DeleteTripAdjustment(
    $input: DeleteTripAdjustmentInput!
    $condition: ModelTripAdjustmentConditionInput
  ) {
    deleteTripAdjustment(input: $input, condition: $condition) {
      tripId
      id
      username
      vehicleId
      tripSegmentId
      type
      state
      stateCode
      cordonId
      cordonName
      todId
      todName
      tollId
      adjMileage
      adjMileageFeeCents
      adjFuel
      adjFuelFeeCents
      adjCordonFeeCents
      adjTodFeeCents
      adjTollFeeCents
      note
      content
      createdBy
      createdAt
      transactionId
      paymentStatus
      updatedAt
    }
  }
`;
export const createTripBin = /* GraphQL */ `
  mutation CreateTripBin(
    $input: CreateTripBinInput!
    $condition: ModelTripBinConditionInput
  ) {
    createTripBin(input: $input, condition: $condition) {
      tripId
      id
      username
      lat
      lng
      timestamp
      timeZone
      createdAt
      updatedAt
    }
  }
`;
export const updateTripBin = /* GraphQL */ `
  mutation UpdateTripBin(
    $input: UpdateTripBinInput!
    $condition: ModelTripBinConditionInput
  ) {
    updateTripBin(input: $input, condition: $condition) {
      tripId
      id
      username
      lat
      lng
      timestamp
      timeZone
      createdAt
      updatedAt
    }
  }
`;
export const deleteTripBin = /* GraphQL */ `
  mutation DeleteTripBin(
    $input: DeleteTripBinInput!
    $condition: ModelTripBinConditionInput
  ) {
    deleteTripBin(input: $input, condition: $condition) {
      tripId
      id
      username
      lat
      lng
      timestamp
      timeZone
      createdAt
      updatedAt
    }
  }
`;
export const createTripSegment = /* GraphQL */ `
  mutation CreateTripSegment(
    $input: CreateTripSegmentInput!
    $condition: ModelTripSegmentConditionInput
  ) {
    createTripSegment(input: $input, condition: $condition) {
      id
      tripId
      transactionId
      username
      vehicleId
      createdAt
      tsStart
      tsEnd
      type
      state
      stateCode
      cordonId
      cordonName
      cordonEnterTime
      cordonEnterTimeZone
      cordonEnterFeeCents
      todId
      tollTxId
      tollId
      tollEntryId
      tollExitId
      tollEntryTime
      tollExitTime
      polylines
      calculatedMileage
      percentage
      mileage
      fuel
      mileageFeeCents
      fuelFeeCents
      cordonFeeCents
      todFeeCents
      tollFeeCents
      tripBinIds
      paymentStatus
      updatedAt
    }
  }
`;
export const updateTripSegment = /* GraphQL */ `
  mutation UpdateTripSegment(
    $input: UpdateTripSegmentInput!
    $condition: ModelTripSegmentConditionInput
  ) {
    updateTripSegment(input: $input, condition: $condition) {
      id
      tripId
      transactionId
      username
      vehicleId
      createdAt
      tsStart
      tsEnd
      type
      state
      stateCode
      cordonId
      cordonName
      cordonEnterTime
      cordonEnterTimeZone
      cordonEnterFeeCents
      todId
      tollTxId
      tollId
      tollEntryId
      tollExitId
      tollEntryTime
      tollExitTime
      polylines
      calculatedMileage
      percentage
      mileage
      fuel
      mileageFeeCents
      fuelFeeCents
      cordonFeeCents
      todFeeCents
      tollFeeCents
      tripBinIds
      paymentStatus
      updatedAt
    }
  }
`;
export const deleteTripSegment = /* GraphQL */ `
  mutation DeleteTripSegment(
    $input: DeleteTripSegmentInput!
    $condition: ModelTripSegmentConditionInput
  ) {
    deleteTripSegment(input: $input, condition: $condition) {
      id
      tripId
      transactionId
      username
      vehicleId
      createdAt
      tsStart
      tsEnd
      type
      state
      stateCode
      cordonId
      cordonName
      cordonEnterTime
      cordonEnterTimeZone
      cordonEnterFeeCents
      todId
      tollTxId
      tollId
      tollEntryId
      tollExitId
      tollEntryTime
      tollExitTime
      polylines
      calculatedMileage
      percentage
      mileage
      fuel
      mileageFeeCents
      fuelFeeCents
      cordonFeeCents
      todFeeCents
      tollFeeCents
      tripBinIds
      paymentStatus
      updatedAt
    }
  }
`;
export const createVehicle = /* GraphQL */ `
  mutation CreateVehicle(
    $input: CreateVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    createVehicle(input: $input, condition: $condition) {
      vin
      make
      model
      year
      type
      licensePlate
      registrationState
      isPrimary
      mroType
      beginningOdometerReading
      currentOdometerReading
      mileage
      fuelTaxCreditCents
      mileageUserFeeCents
      epaVehicleCombinedKpl
      epaVehicleCombinedMpg
      logs
      reports {
        odometer
        evCapacity
        engineOilLife
        tsReportDate
      }
      locations {
        latitude
        longitude
        tsLocationDate
      }
      gotoll {
        vehicleId
        action
        status
        createdAt
        actionAt
        updatedAt
      }
      id
      username
      epaVehicleId
      mroId
      participantConnectionId
      createdBy
      updatedBy
      mro {
        username
        id
        vehicleId
        deviceSerialNumber
        deviceImei
        manufacturer
        model
        hardwareVersion
        firmwareVersion
        gpsEnabled
        configurableParameters
        shippedDate
        shippingCarrier
        shippingTrackingNumber
        deliveredDate
        activationDate
        firstReportedDate
        lastReportedDate
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      connection {
        accessToken
        authorizationCode
        externalVehicleId
        refreshToken
        resourceProvider
        id
        username
        vehicleId
        createdAt
        updatedAt
        forceRestoreConnection
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVehicle = /* GraphQL */ `
  mutation UpdateVehicle(
    $input: UpdateVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    updateVehicle(input: $input, condition: $condition) {
      vin
      make
      model
      year
      type
      licensePlate
      registrationState
      isPrimary
      mroType
      beginningOdometerReading
      currentOdometerReading
      mileage
      fuelTaxCreditCents
      mileageUserFeeCents
      epaVehicleCombinedKpl
      epaVehicleCombinedMpg
      logs
      reports {
        odometer
        evCapacity
        engineOilLife
        tsReportDate
      }
      locations {
        latitude
        longitude
        tsLocationDate
      }
      gotoll {
        vehicleId
        action
        status
        createdAt
        actionAt
        updatedAt
      }
      id
      username
      epaVehicleId
      mroId
      participantConnectionId
      createdBy
      updatedBy
      mro {
        username
        id
        vehicleId
        deviceSerialNumber
        deviceImei
        manufacturer
        model
        hardwareVersion
        firmwareVersion
        gpsEnabled
        configurableParameters
        shippedDate
        shippingCarrier
        shippingTrackingNumber
        deliveredDate
        activationDate
        firstReportedDate
        lastReportedDate
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      connection {
        accessToken
        authorizationCode
        externalVehicleId
        refreshToken
        resourceProvider
        id
        username
        vehicleId
        createdAt
        updatedAt
        forceRestoreConnection
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVehicle = /* GraphQL */ `
  mutation DeleteVehicle(
    $input: DeleteVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    deleteVehicle(input: $input, condition: $condition) {
      vin
      make
      model
      year
      type
      licensePlate
      registrationState
      isPrimary
      mroType
      beginningOdometerReading
      currentOdometerReading
      mileage
      fuelTaxCreditCents
      mileageUserFeeCents
      epaVehicleCombinedKpl
      epaVehicleCombinedMpg
      logs
      reports {
        odometer
        evCapacity
        engineOilLife
        tsReportDate
      }
      locations {
        latitude
        longitude
        tsLocationDate
      }
      gotoll {
        vehicleId
        action
        status
        createdAt
        actionAt
        updatedAt
      }
      id
      username
      epaVehicleId
      mroId
      participantConnectionId
      createdBy
      updatedBy
      mro {
        username
        id
        vehicleId
        deviceSerialNumber
        deviceImei
        manufacturer
        model
        hardwareVersion
        firmwareVersion
        gpsEnabled
        configurableParameters
        shippedDate
        shippingCarrier
        shippingTrackingNumber
        deliveredDate
        activationDate
        firstReportedDate
        lastReportedDate
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      connection {
        accessToken
        authorizationCode
        externalVehicleId
        refreshToken
        resourceProvider
        id
        username
        vehicleId
        createdAt
        updatedAt
        forceRestoreConnection
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey(
    $input: CreateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    createSurvey(input: $input, condition: $condition) {
      id
      surveyId
      questions {
        type
        question
        answer
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey(
    $input: UpdateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    updateSurvey(input: $input, condition: $condition) {
      id
      surveyId
      questions {
        type
        question
        answer
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSurvey = /* GraphQL */ `
  mutation DeleteSurvey(
    $input: DeleteSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    deleteSurvey(input: $input, condition: $condition) {
      id
      surveyId
      questions {
        type
        question
        answer
      }
      createdAt
      updatedAt
    }
  }
`;
