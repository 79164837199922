import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import StarIcon from '@material-ui/icons/Star';

import ControlledInput from 'components/Form/ControlledInput';

import { listPaymentCards } from 'graphql/queries';
import { createPaymentCard, deletePaymentCard, updatePaymentCard } from 'graphql/mutations';
import { asyncListAll, asyncRetryMutation } from 'utilities/graph';
import { systemBillingFeatures } from 'utilities/constants/paymentStatus';

import { useStyles } from './styles';

const currentYear = () => {
  const now = new Date();
  return now.getFullYear();
};

const getExpirationYears = () => {
  const year = currentYear();
  const options = [];
  for (let i = year; i < year + 10; i++) {
    options.push(i);
  }
  return options;
};

const BillingInfo = ({ user, showTitle = true, showSaved = true, onCompleted = () => {} }) => {
  const [error, setError] = useState(false);

  const classes = useStyles();
  const billingFeatures = systemBillingFeatures(localStorage);

  if (!billingFeatures.allowed) {
    return <Disabled user={user} />;
  }

  const handleCloseError = () => {
    setError(false);
  };

  return (
    <Container component="main">
      <div className={classes.paper}>
        {!showTitle ? null : (
          <>
            <Avatar variant="circle" className={classes.avatar}>
              <CreditCardOutlinedIcon color="inherit" />
            </Avatar>
            <Typography component="h1" variant="h5">
              Billing Info
            </Typography>
          </>
        )}
        {
          billingFeatures.provider === 'tupay' ?
          <Tupay classes={classes} user={user} setError={setError} onCompleted={onCompleted} /> :
          <ManualEntry classes={classes} user={user} setError={setError} onCompleted={onCompleted} />
        }

        <Snackbar
          open={error}
          autoHideDuration={5000}
          onClose={handleCloseError}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={handleCloseError}>
            {error}
          </Alert>
        </Snackbar>
      </div>

      {!showSaved ? null : <Stored classes={classes} user={user} setError={setError} />}
    </Container>
  );
};

BillingInfo.propTypes = {
  user: PropTypes.object,
  showTitle: PropTypes.bool,
  showSaved: PropTypes.bool,
  onCompleted: PropTypes.func,
};

const Stored = ({ classes, user, setError = () => {} }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const { username } = user;

  const refreshPaymentMethods = async (username) => {
    try {
      const paymentCards = await asyncListAll(listPaymentCards, { username });
      setPaymentMethods(paymentCards || []);
    } catch (e) {
      console.warn(e);
      setError(e.message);
    }
  };

  useEffect(() => {
    (async () => {
      await refreshPaymentMethods(username);
    })();
  }, [username]);

  if (paymentMethods.length === 0) {
    return <></>;
  }

  const handleRemoveCard = async (id) => {
    try {
      setDisabled(true);
      await asyncRetryMutation(deletePaymentCard, {
        input: {
          id,
          username,
        },
      });
    } catch (e) {
      setError(e.message);
    } finally {
      setDisabled(false);
      await refreshPaymentMethods(username);
    }
  };

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">
        Payment Methods
      </Typography>
      <List>
        {paymentMethods.map((method) => {
          return (
            <ListItem key={`payment-method-${method.id}`}>
              {!method.isDefault ? null : (
                <ListItemIcon>
                  <StarIcon color="primary" />
                </ListItemIcon>
              )}
              <ListItemText
                primary={method.alias ? method.alias : `**** **** **** ${method.last4}`}
                secondary={`Expires on ${method.expirationMonth}/${method.expirationYear}`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  disabled={disabled}
                  onClick={() => handleRemoveCard(method.id)}
                >
                  <DeleteIcon color="inherit" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

Stored.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  setError: PropTypes.func,
};

const Disabled = ({ classes, user }) => {
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar variant="circle" className={classes.avatar}>
          <CreditCardOutlinedIcon color="inherit" />
        </Avatar>
        <Typography variant="body1">
          Payment methods are currently disabled in the system and will not be used.
        </Typography>
      </div>
    </Container>
  );
};

Disabled.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
};

const Tupay = ({ classes, user, setError = () => {}, onCompleted = () => {} }) => {
  const [loaded, setLoaded] = useState(false);
  const { username } = user;

  useEffect(() => {
    if (loaded) {
      return;
    }

    const handleSavePaymentCard = async ({
      brand,
      expirationMonth,
      expirationYear,
      externalId,
      isDefault,
      last4,
      nameOnCard,
      serviceProviderKey,
      token,
    }) => {
      const now = new Date();
      const expiration = new Date(expirationYear, expirationMonth, 0);
      if (now.getTime() > expiration.getTime()) {
        setError('The card has expired');
        return;
      }

      await asyncRetryMutation(createPaymentCard, {
        input: {
          brand: brand.toLowerCase(),
          expirationMonth: parseInt(expirationMonth, 10),
          expirationYear: parseInt(expirationYear, 10),
          externalId: externalId,
          isDefault,
          last4: last4,
          nameOnCard,
          updatedBy: localStorage.getItem('ruc:username'),
          username,
          serviceProviderKey,
          token,
        },
      });
    };

    const handleTokenGenerate = (res) => {
      // Expected structure
      // res: {
      //   token: "",
      //   serviceProviderKey: "",
      //   card: {
      //     cardExpMonth: "01",
      //     cardExpYear: "2024",
      //     cardLastFour: "1111",
      //     cardType: "Visa",
      //     cardHolderEmail: "charles.f.xavier@xmen.org",
      //   },
      //   transaction: {
      //     amount: 100,
      //     customOrderId: "custom_order_id",
      //     customTransactionId: "custom_transaction_id",
      //     status: "Approved",
      //     traceTransactionId: "3tF6UTQFdB8fA7w1sfkkiS",
      //     verificationCode: "SFKKIS",
      //   },
      // }
      (async () => {
        try {
          await handleSavePaymentCard({
            brand: res.card.cardType,
            expirationMonth: res.card.cardExpMonth,
            expirationYear: res.card.cardExpYear,
            externalId: res.paymentId,
            isDefault: true,
            last4: res.card.cardLastFour,
            nameOnCard: res.card.cardHolderName,
            serviceProviderKey: res.serviceProviderKey,
            token: res.token,
          });
          onCompleted();
        } catch (e) {
          console.warn(e);
          setError(e.message);
        }
      })();
    };

    const pay = new window.Pay('092e74c9808842e582fd9fca21a75d0d');
    pay.createPaymentElement('#pay-tupay', {
      url: 'https://ui.el.pay.gwa.tu.nxbos-preview.net',
      paymentMethodType: 'token',
      serviceProviderShortCodes: [],
      onTokenGenerate: handleTokenGenerate,
      onError: (err) => {
        console.error('onError:', err);
      },
      appearance: {
        paymentCopy: 'Please provide your payment details.',
        submitButtonText: 'Add payment method',
        theme: '.MuiButton-contained { color: #fff !important; background-color: rgb(11, 20, 56) !important; } ' +
               '.MuiButton-outlined { color: rgb(11, 20, 56) !important; border: 1px solid rgba(11, 20, 56, 0.5) !important; } ' +
               '.MuiButton-outlined:hover { background-color: rgba(11, 20, 56, 0.04) !important; } ' +
               '.Mui-focused { color: rgb(11, 20, 56) !important; } ' +
               '.Mui-focused fieldset { border: 1px solid rgb(11, 20, 56) !important; }',
      },
    });

    setLoaded(true);
  }, [loaded, setError, username]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div id="pay-tupay" className={classes.tupay}></div>
      </Grid>
    </Grid>
  );
};

Tupay.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  setError: PropTypes.func,
  onCompleted: PropTypes.func,
};

const ManualEntry = ({ classes, user, setError = () => {}, onCompleted = () => {} }) => {
  // form state
  const { control, errors, handleSubmit, formState, setValue } = useForm();
  const { isSubmitting } = formState;

  // billing state
  const [defaultPaymentCard] = useState({});
  const { username } = user;

  const cardInputs = [{
    type: 'text',
    name: 'cardNumber',
    defaultValue: `**** **** **** ${defaultPaymentCard.last4 || '****'}`,
    label: 'Card Number',
    required: true,
    disabled: true,
    invalidText: 'Card number is required',
  }, {
    type: 'text',
    name: 'nameOnCard',
    defaultValue: `${defaultPaymentCard.nameOnCard || ''}`,
    label: 'Name On Card',
    required: true,
    invalidText: 'Name on card is required',
  }, {
    type: 'select',
    name: 'expirationMonth',
    defaultValue: `${defaultPaymentCard.expirationMonth || 1}`,
    label: 'Exp Month',
    required: true,
    invalidText: 'Expiration month is required',
    options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
      return {
        value: month,
        label: month,
      };
    }),
  }, {
    type: 'select',
    name: 'expirationYear',
    defaultValue: `${defaultPaymentCard.expirationYear || currentYear()}`,
    label: 'Exp Year',
    required: true,
    invalidText: 'Expiration year is required',
    options: getExpirationYears().map((year) => {
      return {
        value: year,
        label: year,
      };
    }),
  }, {
    type: 'checkbox',
    name: 'isDefault',
    label: 'Set as default payment method',
    defaultChecked: true,
    disabled: true,
    setValue,
  }];

  async function handleSavePaymentCard({
    cardNumber,
    expirationMonth,
    expirationYear,
    isDefault,
    nameOnCard,
  }) {
    const now = new Date();
    const expiration = new Date(expirationYear, expirationMonth, 0);
    if (now.getTime() > expiration.getTime()) {
      setError('The card has expired');
      return;
    }

    if (defaultPaymentCard.id) {
      await asyncRetryMutation(updatePaymentCard, {
        input: {
          expirationMonth: parseInt(expirationMonth, 10),
          expirationYear: parseInt(expirationYear, 10),
          id: defaultPaymentCard.id,
          isDefault,
          nameOnCard,
          updatedBy: localStorage.getItem('ruc:username'),
          username,
        },
      });
    } else {
      await asyncRetryMutation(createPaymentCard, {
        input: {
          brand: 'visa',
          expirationMonth,
          expirationYear,
          externalId: '1',
          isDefault: true,
          last4: cardNumber.substring(cardNumber.length - 4),
          nameOnCard,
          updatedBy: localStorage.getItem('ruc:username'),
          username,
        },
      });
    }

    onCompleted();
  }

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(handleSavePaymentCard)}
      noValidate
    >
      <Grid container spacing={2}>
        {cardInputs.splice(0, 2).map((input, index) => {
          return (
            <Grid item xs={12} key={index}>
              <ControlledInput
                control={control}
                errors={errors}
                {...input}
              />
            </Grid>
          );
        })}

        {cardInputs.splice(0, 2).map((input, index) => {
          return (
            <Grid item xs={6} key={index}>
              <ControlledInput
                control={control}
                errors={errors}
                {...input}
              />
            </Grid>
          );
        })}

        {cardInputs.map((input, index) => {
          return (
            <Grid item xs={12} key={index}>
              <ControlledInput
                control={control}
                errors={errors}
                {...input}
              />
            </Grid>
          );
        })}

        <Grid item xs={12}>
          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

ManualEntry.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  setError: PropTypes.func,
  onCompleted: PropTypes.func,
};

export default BillingInfo;
