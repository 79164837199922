import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Link as RouteLink,
  useHistory,
} from 'react-router-dom';

import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import PaymentIcon from '@material-ui/icons/Payment';
import Snackbar from '@material-ui/core/Snackbar';
import StarIcon from '@material-ui/icons/Star';
import Typography from '@material-ui/core/Typography';

import { getTransaction, listPaymentCards } from 'graphql/queries';
import { createPaymentCard, updateTransaction } from 'graphql/mutations';
import { asyncGet, asyncListAll, asyncRetryMutation } from 'utilities/graph';
import { systemBillingFeatures } from 'utilities/constants/paymentStatus';

import { useStyles } from './styles';

const PaymentButton = ({ edge = 'both', transactionId = '', text = '', balance = 0, showText = true }) => {
  if (balance <= 0) {
    return <></>;
  }

  if (!text || text === '') {
    text = `Pay $${balance.toFixed(2)}`;
  }

  return (
    <IconButton edge={edge} aria-label="pay" component={RouteLink} to={`/payment/${transactionId}`} title={text}>
      {!showText ? null : <Typography component="span">{text}</Typography>}
      <PaymentIcon />
    </IconButton>
  );
};

PaymentButton.propTypes = {
  edge: PropTypes.string,
  transactionId: PropTypes.string,
  text: PropTypes.string,
  balance: PropTypes.number,
  showText: PropTypes.bool,
};

const Payment = ({ user, title = 'Make A Payment', transactionId: inTransactionId, computedMatch }) => {
  const [error, setError] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [transaction, setTransaction] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const { username } = user;

  const classes = useStyles();
  const history = useHistory();
  const billingFeatures = systemBillingFeatures(localStorage);

  useEffect(() => {
    if (inTransactionId) {
      setTransactionId(inTransactionId);
    } else if (computedMatch) {
      const { params: { transactionId: computedTransactionId } } = computedMatch;
      setTransactionId(computedTransactionId);
    }
  }, [inTransactionId, computedMatch]);

  useEffect(() => {
    (async () => {
      try {
        const { data: { getTransaction: tx } } = await asyncGet(getTransaction, {
          id: transactionId,
        }, {
          bypassCache: true,
        });
        setTransaction(tx);
        console.log(tx);
      } catch (e) {
        console.warn(e);
        setError(e.message);
      }
    })();
  }, [transactionId]);

  if (!billingFeatures.allowed) {
    return history.push('/dashboard');
  }

  const handleCloseError = () => {
    setError(false);
  };

  const onPaymentSelected = async (method) => {
    setPaymentMethod(method);

    if (transaction === null) {
      return;
    }

    switch (transaction.status) {
      case 'pending':
        // transaction is currently being processed
        setError('Transaction is already being processed');
        return;
      case 'processed':
        // transaction already processed
        setError('Transaction has already been processed.');
        return;
      case 'failed':
      case 'created':
      default:
        break;
    }

    try {
      await asyncRetryMutation(updateTransaction, {
        input: Object.assign({ ...transaction }, {
          status: 'pending',
          paymentMethodId: method.id,
        }),
      });
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <Container component="main">
      <div className={classes.paper}>
        <Avatar variant="circle" className={classes.avatar}>
          <CreditCardOutlinedIcon color="inherit" />
        </Avatar>
        <Typography component="h1" variant="h5">Make A Payment</Typography>

        {!paymentMethod ? null :
         <SelectPaymentMethod username={username} billingFeatures={billingFeatures} classes={classes} onPaymentSelected={onPaymentSelected} />}
        {!paymentMethod ? null :
         <NewPaymentMethod username={username} billingFeatures={billingFeatures} classes={classes} onPaymentSelected={onPaymentSelected} />}

        <Snackbar
          open={error}
          autoHideDuration={5000}
          onClose={handleCloseError}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Alert
            severity="error"
            variant="filled"
            onClose={handleCloseError}>
            {error}
          </Alert>
        </Snackbar>
      </div>
    </Container>
  );
};

Payment.propTypes = {
  user: PropTypes.object,
  title: PropTypes.string,
  transactionId: PropTypes.string,
  computedMatch: PropTypes.object,
};

const SelectPaymentMethod = ({ username, billingFeatures, classes, onPaymentSelected, setError }) => {
  const [disabled, setDisabled] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const refreshPaymentMethods = async (username) => {
    try {
      const paymentCards = await asyncListAll(listPaymentCards, { username });
      setPaymentMethods(paymentCards || []);
    } catch (e) {
      console.warn(e);
      setError(e.message);
    }
  };

  useEffect(() => {
    (async () => {
      await refreshPaymentMethods(username);
    })();
  }, [username]);


  const handleSelectItem = (method) => {
    setDisabled(true);
    onPaymentSelected(method);
  };

  const existing = () => {
    if (paymentMethods.length === 0) {
      return <></>;
    }

    return (
      <div>
        <Typography component="h1" variant="h5">Existing Payment Methods</Typography>
        <List>
          {paymentMethods.map((method) => {
            return (
              <ListItem key={`payment-method-${method.id}`}>
                {!method.isDefault ? null : (
                  <ListItemIcon>
                    <StarIcon color="primary" />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={method.alias ? method.alias : `**** **** **** ${method.last4}`}
                  secondary={`Expires on ${method.expirationMonth}/${method.expirationYear}`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    disabled={disabled}
                    onClick={() => handleSelectItem(method)}
                  >
                    <PaymentIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
    </div>
    );
  };

  return (
    <div className={classes.paper}>
      <hr className={classes.divider} />
      {existing()}
    </div>
  );
};

SelectPaymentMethod.propTypes = {
  username: PropTypes.string,
  billingFeatures: PropTypes.object,
  classes: PropTypes.object,
  onPaymentSelected: PropTypes.func,
  setError: PropTypes.func,
};

const NewPaymentMethod = ({ username, billingFeatures, classes, onPaymentSelected, setError }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) {
      return;
    }

    const handleSavePaymentCard = async ({
      brand,
      expirationMonth,
      expirationYear,
      externalId,
      isDefault,
      last4,
      nameOnCard,
      serviceProviderKey,
      token,
    }) => {
      const now = new Date();
      const expiration = new Date(expirationYear, expirationMonth, 0);
      if (now.getTime() > expiration.getTime()) {
        setError('The card has expired');
        return;
      }

      const nextMethod = await asyncRetryMutation(createPaymentCard, {
        input: {
          brand: brand.toLowerCase(),
          expirationMonth: parseInt(expirationMonth, 10),
          expirationYear: parseInt(expirationYear, 10),
          externalId: externalId,
          isDefault,
          last4: last4,
          nameOnCard,
          updatedBy: localStorage.getItem('ruc:username'),
          username,
          serviceProviderKey,
          token,
        },
      });
      onPaymentSelected(nextMethod);
    };

    const handleTokenGenerate = (res) => {
      // Expected structure
      // res: {
      //   token: "",
      //   serviceProviderKey: "",
      //   card: {
      //     cardExpMonth: "01",
      //     cardExpYear: "2024",
      //     cardLastFour: "1111",
      //     cardType: "Visa",
      //     cardHolderEmail: "charles.f.xavier@xmen.org",
      //   },
      //   transaction: {
      //     amount: 100,
      //     customOrderId: "custom_order_id",
      //     customTransactionId: "custom_transaction_id",
      //     status: "Approved",
      //     traceTransactionId: "3tF6UTQFdB8fA7w1sfkkiS",
      //     verificationCode: "SFKKIS",
      //   },
      // }
      (async () => {
        try {
          await handleSavePaymentCard({
            brand: res.card.cardType,
            expirationMonth: res.card.cardExpMonth,
            expirationYear: res.card.cardExpYear,
            externalId: res.token,
            isDefault: true,
            last4: res.card.cardLastFour,
            nameOnCard: res.card.cardHolderName,
            serviceProviderKey: res.serviceProviderKey,
            token: res.token,
          });
        } catch (e) {
          console.warn(e);
          setError(e.message);
        }
      })();
    };

    const pay = new window.Pay('092e74c9808842e582fd9fca21a75d0d');
    pay.createPaymentElement('#pay-tupay', {
      url: 'https://ui.el.pay.gwa.tu.nxbos-preview.net',
      paymentMethodType: 'token',
      serviceProviderShortCodes: [],
      onTokenGenerate: handleTokenGenerate,
      onError: (err) => {
        console.error('onError:', err);
      },
      appearance: {
        paymentCopy: 'Please provide your payment details.',
        submitButtonText: 'Add payment method',
        theme: '.MuiButton-contained { color: #fff !important; background-color: rgb(11, 20, 56) !important; } ' +
               '.MuiButton-outlined { color: rgb(11, 20, 56) !important; border: 1px solid rgba(11, 20, 56, 0.5) !important; } ' +
               '.MuiButton-outlined:hover { background-color: rgba(11, 20, 56, 0.04) !important; } ' +
               '.Mui-focused { color: rgb(11, 20, 56) !important; } ' +
               '.Mui-focused fieldset { border: 1px solid rgb(11, 20, 56) !important; }',
      },
    });

    setLoaded(true);
  }, [loaded, setError, username]);

  return (
    <div className={classes.paper}>
      <hr className={classes.divider} />
      <Typography component="h1" variant="h5">
        New Payment Method
      </Typography>
      <div id="pay-tupay" className={classes.tupay}></div>
    </div>
  );
};

NewPaymentMethod.propTypes = {
  username: PropTypes.string,
  billingFeatures: PropTypes.object,
  classes: PropTypes.object,
  onPaymentSelected: PropTypes.func,
  setError: PropTypes.func,
};

export {
  Payment,
  PaymentButton,
  SelectPaymentMethod,
  NewPaymentMethod,
};
