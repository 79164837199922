import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  connect,
  rucScope,
  vehicleIsSmartcarCompatible,
} from 'utilities/smartcar';

import { useStyles } from './commonStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import DeviceExplanation from './DeviceExplanation';

import ControlledInput from 'components/Form/ControlledInput';

import { mroDevicePreferences } from 'utilities/constants';
import {
  updateParticipant,
  createParticipantConnection,
  updateVehicle,
} from 'graphql/mutations';
import {
  asyncRetryMutation,
} from 'utilities/graph';

const RegisterReportingOptions = ({
  cognitoUser,
  pilotProgram,
  onPreviousStep,
  onCompleteStep,
  vehicle,
}) => {
  const classes = useStyles();

  // props
  const { username } = cognitoUser;

  // preference form state
  const { control, errors, handleSubmit, formState, watch } = useForm();
  const { isSubmitting } = formState;
  const watchMroDevicePreference = watch('mroDevicePreference');

  // overall state
  const [error, setError] = useState(false);
  const [smartcarConnectComplete, setSmartcarConnectComplete] = useState(false);
  const [reportingOptions, setReportingOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);

  // determine available options and default selected
  useEffect(() => {
    const options = mroDevicePreferences;
    options.forEach((_, index) => {
      options[index].disabled = false;
    });
    let defaultOption = 'automatedWithoutLocation';

    // electric vehicles should disable device options and select telematics
    if (vehicle.type === 'electric') {
      options.filter(({ value }) => value !== 'telematics').forEach((option) => {
        option.disabled = true;
      });
      defaultOption = 'telematics';
    }

    // all non-standard MBUF programs require automated device with location
    if (pilotProgram.shortName !== 'MBUF') {
      options.forEach((option) => {
        option.disabled = (option.value !== 'automatedWithLocation') ? true : false;
      });
      defaultOption = 'automatedWithLocation';
    }

    // disable telematics for known incompatible vehicles
    const compatible = vehicleIsSmartcarCompatible(vehicle.make.toUpperCase(), vehicle.year);
    if (!compatible) {
      options.filter(({ value }) => value === 'telematics').forEach((option) => {
        option.disabled = true;
      });
    }

    setReportingOptions(options);
    setDefaultOption(defaultOption);
  }, [reportingOptions, defaultOption, vehicle, pilotProgram]);

  if (!reportingOptions.length || !defaultOption) {
    return null;
  }

  const preferenceInputs = [{
    type: 'radio',
    name: 'mroDevicePreference',
    defaultValue: defaultOption,
    value: defaultOption,
    renderOptions: {
      row: true,
      labelPlacement: 'bottom',
    },
    options: reportingOptions,
    invalidText: 'Preferred reporting option is required',
  }];

  async function handleRegisterReportingOption({ mroDevicePreference }) {
    try {
      await asyncRetryMutation(updateParticipant, {
        input: {
          username,
          mroDevicePreference,
          updatedBy: localStorage.getItem('ruc:username'),
        },
      });

      onCompleteStep();
    } catch (e) {
      setError(e.message);
      return;
    }
  }

  async function handleSmartcarConnect() {
    try {
      const code = await connect(rucScope, {
        vehicleInfo: {
          make: vehicle.make.toUpperCase(),
        },
      });

      setSmartcarConnectComplete(true);

      const response = await asyncRetryMutation(createParticipantConnection, {
        input: {
          username,
          authorizationCode: code,
          resourceProvider: 'smartcar',
          vehicleId: vehicle.id,
        },
      });

      // update vehicle
      const {
        data: {
          createParticipantConnection: {
            id: participantConnectionId,
          },
        },
      } = response;

      await asyncRetryMutation(updateVehicle, {
        input: Object.assign({}, vehicle, {
          participantConnectionId,
        }),
      });

      handleRegisterReportingOption({
        mroDevicePreference: 'telematics',
      });
    } catch (e) {
      setSmartcarConnectComplete(false);

      // user denies access: "User denied access to the requested scope of permissions."
      console.warn(e.message);

      // disable telematics
      const options = Object.assign({}, reportingOptions);
      options.forEach((option) => {
        if (option.value === 'telematics') {
          option.disabled = true;
        }
      });

      setReportingOptions(options);
      setDefaultOption('automatedWithLocation');
    }
  }

  function handleCloseError() {
    setError(false);
  }

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">Program Reporting Options</Typography>
      <DeviceExplanation preference={watchMroDevicePreference || defaultOption} />
      <form
        className={classes.form}
        onSubmit={handleSubmit(handleRegisterReportingOption)}
        noValidate
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box marginBottom={4}>
              {preferenceInputs.map((input, index) => {
                return (
                  <ControlledInput
                    key={index}
                    control={control}
                    errors={errors}
                    {...input}
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              type="button"
              size="large"
              fullWidth
              variant="contained"
              color="inherit"
              className={classes.secondaryAction}
              disabled={smartcarConnectComplete}
              onClick={() => {
                onPreviousStep();
              }}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={(e) => {
                if (
                  watchMroDevicePreference && watchMroDevicePreference === 'telematics' ||
                  !watchMroDevicePreference && defaultOption === 'telematics'
                ) {
                  e.preventDefault();
                  handleSmartcarConnect();
                }
              }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={error}
        autoHideDuration={5000}
        onClose={handleCloseError}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          onClose={handleCloseError}>
          {error}
        </Alert>
      </Snackbar>
    </div >
  );
};

RegisterReportingOptions.propTypes = {
  cognitoUser: PropTypes.object,
  pilotProgram: PropTypes.object,
  onPreviousStep: PropTypes.func,
  onCompleteStep: PropTypes.func,
  vehicle: PropTypes.object,
};

export default RegisterReportingOptions;
