import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './commonStyles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import BillingExplanation from './BillingExplanation';

import BillingInfo from 'pages/Participant/Account/components/BillingInfo';
import { systemBillingFeatures } from 'utilities/constants/paymentStatus';

const RegisterBilling = ({
  participant,
  username,
  onPreviousStep,
  onCompleteStep,
}) => {
  const classes = useStyles();
  const billingFeatures = systemBillingFeatures(localStorage);

  // overall state
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(false);

  function handleCloseError() {
    setError(false);
  }

  function handleCompleted() {
    setCompleted(true);
  }

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">Billing Information</Typography>
      {billingFeatures.provider === 'tupay' ? null : <BillingExplanation />}
      {completed ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.paper}>
              <CheckCircleOutlineIcon className={classes.billingSuccess} />
              <p>
                Successfully added payment method!
              </p>
            </div>
          </Grid>
        </Grid>
      ) : (
        <BillingInfo
          user={{ username: username }}
          showTitle={false}
          showSaved={false}
          onCompleted={handleCompleted} />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Button
            type="button"
            size="large"
            fullWidth
            variant="contained"
            color="inherit"
            className={classes.secondaryAction}
            onClick={() => {
              onPreviousStep();
            }}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!completed}
          >
            Continue
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        open={error}
        autoHideDuration={5000}
        onClose={handleCloseError}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          onClose={handleCloseError}>
          {error}
        </Alert>
      </Snackbar>
    </div >
  );
};

RegisterBilling.propTypes = {
  participant: PropTypes.object,
  username: PropTypes.string,
  onPreviousStep: PropTypes.func,
  onCompleteStep: PropTypes.func,
};

export default RegisterBilling;
