import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import TransactionsTable from 'pages/Admin/components/TransactionsTable';

import { asyncListAll } from 'utilities/graph';
import { getTransactionsByUsernameByCreatedAt } from 'graphql/queries';

import { useStyles } from './styles';

function Payments({ username, title = 'My Payments' }) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const transactions = await asyncListAll(getTransactionsByUsernameByCreatedAt, {
          username,
          sortDirection: 'DESC',
        }, {
          bypassCache: true,
        });
        setTransactions(transactions);
      } catch (e) {
        console.warn(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [username]);

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <Container component="main">
      <TransactionsTable
        title={title}
        data={transactions}
        viewer="participant"
      />
    </Container>
  );
}

Payments.propTypes = {
  username: PropTypes.string,
  title: PropTypes.string,
};

export default Payments;
