/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getConfiguration = /* GraphQL */ `
  query GetConfiguration($key: String!) {
    getConfiguration(key: $key) {
      key
      name
      description
      value
      options {
        name
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const listConfigurations = /* GraphQL */ `
  query ListConfigurations(
    $key: String
    $filter: ModelConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listConfigurations(
      key: $key
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        key
        name
        description
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCordon = /* GraphQL */ `
  query GetCordon($id: ID!) {
    getCordon(id: $id) {
      id
      state
      name
      description
      zones {
        name
        description
        color
        polyline
      }
      entryFeeCents
      exitFeeCents
      centsPerMileageUnit
      days
      internalStart
      internalEnd
      minimumDistanceInMileageUnit
      minimumTotalDistanceInMileageUnit
      createdAt
      updatedAt
    }
  }
`;
export const listCordons = /* GraphQL */ `
  query ListCordons(
    $filter: ModelCordonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCordons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        state
        name
        description
        entryFeeCents
        exitFeeCents
        centsPerMileageUnit
        days
        internalStart
        internalEnd
        minimumDistanceInMileageUnit
        minimumTotalDistanceInMileageUnit
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEpaVehicle = /* GraphQL */ `
  query GetEpaVehicle($id: ID!) {
    getEpaVehicle(id: $id) {
      id
      make
      model
      year
      cylinders
      trany
      fuelType
      fuelType1
      fuelType2
      mpgCombined
      mpgCity
      mpgHighway
      kplCombined
      kplCity
      kplHighway
      createdAt
      updatedAt
    }
  }
`;
export const listEpaVehicles = /* GraphQL */ `
  query ListEpaVehicles(
    $filter: ModelEpaVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEpaVehicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        make
        model
        year
        cylinders
        trany
        fuelType
        fuelType1
        fuelType2
        mpgCombined
        mpgCity
        mpgHighway
        kplCombined
        kplCity
        kplHighway
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($key: String!, $timestamp: AWSDateTime!) {
    getEvent(key: $key, timestamp: $timestamp) {
      key
      timestamp
      username
      updatedBy
      eventId
      eventName
      diff {
        key
        old
        new
      }
      note
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $key: String
    $timestamp: ModelStringKeyConditionInput
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEvents(
      key: $key
      timestamp: $timestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        key
        timestamp
        username
        updatedBy
        eventId
        eventName
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMROEvent = /* GraphQL */ `
  query GetMROEvent($id: ID!) {
    getMROEvent(id: $id) {
      id
      mroDeviceSerialNumber
      username
      vehiceId
      code
      odometerReadingStart
      odometerReading
      mileage
      flagged
      tripId
      type
      description
      dateTime
      source
      isProcessed
      processLogs
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMROEvents = /* GraphQL */ `
  query ListMROEvents(
    $filter: ModelMROEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMROEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mroDeviceSerialNumber
        username
        vehiceId
        code
        odometerReadingStart
        odometerReading
        mileage
        flagged
        tripId
        type
        description
        dateTime
        source
        isProcessed
        processLogs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMRORate = /* GraphQL */ `
  query GetMRORate($mroType: MileageReportingOptionTypes!, $state: String!) {
    getMRORate(mroType: $mroType, state: $state) {
      mroType
      state
      centsPerMileageUnit
      centsPerFuelUnit
      createdAt
      updatedAt
    }
  }
`;
export const listMRORates = /* GraphQL */ `
  query ListMRORates(
    $mroType: MileageReportingOptionTypes
    $state: ModelStringKeyConditionInput
    $filter: ModelMRORateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMRORates(
      mroType: $mroType
      state: $state
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        mroType
        state
        centsPerMileageUnit
        centsPerFuelUnit
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMileageReport = /* GraphQL */ `
  query GetMileageReport($username: String!, $id: ID!) {
    getMileageReport(username: $username, id: $id) {
      auditStatus
      id
      odoMileage
      photos {
        mimeType
        size
        storageKey
      }
      reason
      tsReportDate
      updatedBy
      username
      vehicleId
      createdAt
      updatedAt
    }
  }
`;
export const listMileageReports = /* GraphQL */ `
  query ListMileageReports(
    $username: String
    $id: ModelIDKeyConditionInput
    $filter: ModelMileageReportFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMileageReports(
      username: $username
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        auditStatus
        id
        odoMileage
        reason
        tsReportDate
        updatedBy
        username
        vehicleId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMileageReportingOption = /* GraphQL */ `
  query GetMileageReportingOption($username: String!, $id: String!) {
    getMileageReportingOption(username: $username, id: $id) {
      username
      id
      vehicleId
      deviceSerialNumber
      deviceImei
      manufacturer
      model
      hardwareVersion
      firmwareVersion
      gpsEnabled
      configurableParameters
      shippedDate
      shippingCarrier
      shippingTrackingNumber
      deliveredDate
      activationDate
      firstReportedDate
      lastReportedDate
      vehicle {
        vin
        make
        model
        year
        type
        licensePlate
        registrationState
        isPrimary
        mroType
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        logs
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const listMileageReportingOptions = /* GraphQL */ `
  query ListMileageReportingOptions(
    $username: String
    $id: ModelStringKeyConditionInput
    $filter: ModelMileageReportingOptionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMileageReportingOptions(
      username: $username
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        id
        vehicleId
        deviceSerialNumber
        deviceImei
        manufacturer
        model
        hardwareVersion
        firmwareVersion
        gpsEnabled
        configurableParameters
        shippedDate
        shippingCarrier
        shippingTrackingNumber
        deliveredDate
        activationDate
        firstReportedDate
        lastReportedDate
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      username
      type
      templateName
      email
      subject
      text
      html
      shouldBeSentAt
      retries
      status
      statusMsg
      sentAt
      createdBy
      createdAt
      logs
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        type
        templateName
        email
        subject
        text
        html
        shouldBeSentAt
        retries
        status
        statusMsg
        sentAt
        createdBy
        createdAt
        logs
        updatedAt
      }
      nextToken
    }
  }
`;
export const getParticipant = /* GraphQL */ `
  query GetParticipant($username: String!) {
    getParticipant(username: $username) {
      address {
        address1
        address2
        city
        state
        postalCode
        extendedPostalCode
      }
      ageGroup
      closedDate
      closedReason
      closedBy
      createdBy
      email
      firstName
      gender
      governmentAffiliation
      isVIP
      lastName
      middleName
      mroDataProviderPreference
      mroDevicePreference
      mroManualCapture
      onboardedDate
      participantPilotProgramId
      phoneNumber
      pilotProgram {
        id
        category
        name
        shortName
        isDeviceRequired
        isGpsRequired
        description
        createdAt
        updatedAt
      }
      preferredContactType
      roadChargeCreditCents
      status
      sumFuelTaxCreditCents
      sumMileageUserFeeCents
      updatedBy
      username
      vehicles {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listParticipants = /* GraphQL */ `
  query ListParticipants(
    $username: String
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listParticipants(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getParticipantConnection = /* GraphQL */ `
  query GetParticipantConnection($username: String!, $id: ID!) {
    getParticipantConnection(username: $username, id: $id) {
      accessToken
      authorizationCode
      externalVehicleId
      refreshToken
      resourceProvider
      id
      username
      vehicleId
      createdAt
      updatedAt
      forceRestoreConnection
    }
  }
`;
export const listParticipantConnections = /* GraphQL */ `
  query ListParticipantConnections(
    $username: String
    $id: ModelIDKeyConditionInput
    $filter: ModelParticipantConnectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listParticipantConnections(
      username: $username
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        accessToken
        authorizationCode
        externalVehicleId
        refreshToken
        resourceProvider
        id
        username
        vehicleId
        createdAt
        updatedAt
        forceRestoreConnection
      }
      nextToken
    }
  }
`;
export const getParticipantInquiry = /* GraphQL */ `
  query GetParticipantInquiry($id: ID!) {
    getParticipantInquiry(id: $id) {
      id
      username
      createdAt
      createdBy
      updatedAt
      updatedBy
      assignee
      category
      subject
      body
      status
      name
      email
      emailOriginalMessageId
      emailS3Key
      bodyHtml
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      comments {
        nextToken
      }
    }
  }
`;
export const listParticipantInquirys = /* GraphQL */ `
  query ListParticipantInquirys(
    $filter: ModelParticipantInquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipantInquirys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        createdAt
        createdBy
        updatedAt
        updatedBy
        assignee
        category
        subject
        body
        status
        name
        email
        emailOriginalMessageId
        emailS3Key
        bodyHtml
      }
      nextToken
    }
  }
`;
export const getParticipantInquiryComment = /* GraphQL */ `
  query GetParticipantInquiryComment($inquiryId: ID!, $id: ID!) {
    getParticipantInquiryComment(inquiryId: $inquiryId, id: $id) {
      id
      inquiryId
      username
      author
      body
      type
      status
      bodyHtml
      emailPayload {
        from
        to
        subject
        text
        inReplyTo
        html
      }
      emailS3Key
      emailAttachments {
        s3Key
        filename
        contentType
        size
      }
      createdAt
      updatedAt
    }
  }
`;
export const listParticipantInquiryComments = /* GraphQL */ `
  query ListParticipantInquiryComments(
    $inquiryId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelParticipantInquiryCommentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listParticipantInquiryComments(
      inquiryId: $inquiryId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        inquiryId
        username
        author
        body
        type
        status
        bodyHtml
        emailS3Key
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getParticipantStatement = /* GraphQL */ `
  query GetParticipantStatement($id: ID!) {
    getParticipantStatement(id: $id) {
      id
      username
      month
      periodFrom
      periodTo
      filename
      mileage
      fuel
      mbufFee
      cordonFee
      todFee
      tollFee
      fuelTaxCredit
      balance
      trips
      note
      createdBy
      shouldSendEmailToParticipant
      createdAt
      updatedAt
    }
  }
`;
export const listParticipantStatements = /* GraphQL */ `
  query ListParticipantStatements(
    $filter: ModelParticipantStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipantStatements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        month
        periodFrom
        periodTo
        filename
        mileage
        fuel
        mbufFee
        cordonFee
        todFee
        tollFee
        fuelTaxCredit
        balance
        trips
        note
        createdBy
        shouldSendEmailToParticipant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPaymentCard = /* GraphQL */ `
  query GetPaymentCard($username: String!, $id: ID!) {
    getPaymentCard(username: $username, id: $id) {
      alias
      billingCountryCode
      billingZip
      brand
      createdAt
      createdBy
      expirationMonth
      expirationYear
      externalId
      fingerprint
      id
      isDefault
      last4
      nameOnCard
      serviceProviderKey
      token
      updatedAt
      updatedBy
      username
    }
  }
`;
export const listPaymentCards = /* GraphQL */ `
  query ListPaymentCards(
    $username: String
    $id: ModelIDKeyConditionInput
    $filter: ModelPaymentCardFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPaymentCards(
      username: $username
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        alias
        billingCountryCode
        billingZip
        brand
        createdAt
        createdBy
        expirationMonth
        expirationYear
        externalId
        fingerprint
        id
        isDefault
        last4
        nameOnCard
        serviceProviderKey
        token
        updatedAt
        updatedBy
        username
      }
      nextToken
    }
  }
`;
export const getPilotProgram = /* GraphQL */ `
  query GetPilotProgram($id: ID!) {
    getPilotProgram(id: $id) {
      id
      category
      name
      shortName
      isDeviceRequired
      isGpsRequired
      description
      details {
        participantsSelected
        assetsAndRoadScope
        participantStatement
        minimumDataRequired
        locationGPS
      }
      participants {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPilotPrograms = /* GraphQL */ `
  query ListPilotPrograms(
    $id: ID
    $filter: ModelPilotProgramFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPilotPrograms(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        category
        name
        shortName
        isDeviceRequired
        isGpsRequired
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTod = /* GraphQL */ `
  query GetTod($id: ID!) {
    getTod(id: $id) {
      id
      name
      days
      internalStart
      internalEnd
      centsPerMileageUnit
      createdAt
      updatedAt
    }
  }
`;
export const listTods = /* GraphQL */ `
  query ListTods(
    $filter: ModelTodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        days
        internalStart
        internalEnd
        centsPerMileageUnit
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getToll = /* GraphQL */ `
  query GetToll($id: String!) {
    getToll(id: $id) {
      id
      state
      name
      createdAt
      updatedAt
    }
  }
`;
export const listTolls = /* GraphQL */ `
  query ListTolls(
    $id: String
    $filter: ModelTollFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTolls(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        state
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      username
      createdAt
      createdBy
      updatedAt
      paymentProcessorResponse {
        referenceNumber
        transactionResponse
      }
      description
      type
      status
      retries
      subtotalCents
      discountCents
      amountCents
      paymentMethodId
      tripSegments {
        nextToken
      }
      tripAdjustments {
        nextToken
      }
      participant {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        createdAt
        createdBy
        updatedAt
        description
        type
        status
        retries
        subtotalCents
        discountCents
        amountCents
        paymentMethodId
      }
      nextToken
    }
  }
`;
export const getTrip = /* GraphQL */ `
  query GetTrip($id: ID!) {
    getTrip(id: $id) {
      id
      recordId
      recordTripId
      mroDeviceSerialNumber
      vehicleVin
      tsStart
      tsEnd
      polyline
      distMro
      odoMro
      distGps
      distRoute
      fuel
      odoGps
      odoMroStart
      odoMroEnd
      fuelStart
      fuelEnd
      fuelAdded
      totalFuel
      odometerBin {
        odo
        timestamp
      }
      username
      vehicleId
      pilotProgramId
      mroId
      createdAt
      processStatus
      processMsg
      processedAt
      logs
      mileageFeeCents
      fuelFeeCents
      cordonFeeCents
      todFeeCents
      tollFeeCents
      reviewStatus
      reviewReason
      polylines {
        polyline
        description
        createdAt
        createdBy
      }
      adjustments {
        nextToken
      }
      notes
      tripSegments {
        nextToken
      }
      vehicle {
        vin
        make
        model
        year
        type
        licensePlate
        registrationState
        isPrimary
        mroType
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        logs
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listTrips = /* GraphQL */ `
  query ListTrips(
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        recordId
        recordTripId
        mroDeviceSerialNumber
        vehicleVin
        tsStart
        tsEnd
        polyline
        distMro
        odoMro
        distGps
        distRoute
        fuel
        odoGps
        odoMroStart
        odoMroEnd
        fuelStart
        fuelEnd
        fuelAdded
        totalFuel
        username
        vehicleId
        pilotProgramId
        mroId
        createdAt
        processStatus
        processMsg
        processedAt
        logs
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        reviewStatus
        reviewReason
        notes
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripAdjustment = /* GraphQL */ `
  query GetTripAdjustment($tripId: ID!, $id: ID!) {
    getTripAdjustment(tripId: $tripId, id: $id) {
      tripId
      id
      username
      vehicleId
      tripSegmentId
      type
      state
      stateCode
      cordonId
      cordonName
      todId
      todName
      tollId
      adjMileage
      adjMileageFeeCents
      adjFuel
      adjFuelFeeCents
      adjCordonFeeCents
      adjTodFeeCents
      adjTollFeeCents
      note
      content
      createdBy
      createdAt
      transactionId
      paymentStatus
      updatedAt
    }
  }
`;
export const listTripAdjustments = /* GraphQL */ `
  query ListTripAdjustments(
    $tripId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelTripAdjustmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTripAdjustments(
      tripId: $tripId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tripId
        id
        username
        vehicleId
        tripSegmentId
        type
        state
        stateCode
        cordonId
        cordonName
        todId
        todName
        tollId
        adjMileage
        adjMileageFeeCents
        adjFuel
        adjFuelFeeCents
        adjCordonFeeCents
        adjTodFeeCents
        adjTollFeeCents
        note
        content
        createdBy
        createdAt
        transactionId
        paymentStatus
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripBin = /* GraphQL */ `
  query GetTripBin($tripId: ID!, $id: ID!) {
    getTripBin(tripId: $tripId, id: $id) {
      tripId
      id
      username
      lat
      lng
      timestamp
      timeZone
      createdAt
      updatedAt
    }
  }
`;
export const listTripBins = /* GraphQL */ `
  query ListTripBins(
    $tripId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelTripBinFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTripBins(
      tripId: $tripId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tripId
        id
        username
        lat
        lng
        timestamp
        timeZone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripSegment = /* GraphQL */ `
  query GetTripSegment($tripId: ID!, $id: ID!) {
    getTripSegment(tripId: $tripId, id: $id) {
      id
      tripId
      transactionId
      username
      vehicleId
      createdAt
      tsStart
      tsEnd
      type
      state
      stateCode
      cordonId
      cordonName
      cordonEnterTime
      cordonEnterTimeZone
      cordonEnterFeeCents
      todId
      tollTxId
      tollId
      tollEntryId
      tollExitId
      tollEntryTime
      tollExitTime
      polylines
      calculatedMileage
      percentage
      mileage
      fuel
      mileageFeeCents
      fuelFeeCents
      cordonFeeCents
      todFeeCents
      tollFeeCents
      tripBinIds
      paymentStatus
      updatedAt
    }
  }
`;
export const listTripSegments = /* GraphQL */ `
  query ListTripSegments(
    $tripId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelTripSegmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTripSegments(
      tripId: $tripId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        tripId
        transactionId
        username
        vehicleId
        createdAt
        tsStart
        tsEnd
        type
        state
        stateCode
        cordonId
        cordonName
        cordonEnterTime
        cordonEnterTimeZone
        cordonEnterFeeCents
        todId
        tollTxId
        tollId
        tollEntryId
        tollExitId
        tollEntryTime
        tollExitTime
        polylines
        calculatedMileage
        percentage
        mileage
        fuel
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        tripBinIds
        paymentStatus
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVehicle = /* GraphQL */ `
  query GetVehicle($username: String!, $id: ID!) {
    getVehicle(username: $username, id: $id) {
      vin
      make
      model
      year
      type
      licensePlate
      registrationState
      isPrimary
      mroType
      beginningOdometerReading
      currentOdometerReading
      mileage
      fuelTaxCreditCents
      mileageUserFeeCents
      epaVehicleCombinedKpl
      epaVehicleCombinedMpg
      logs
      reports {
        odometer
        evCapacity
        engineOilLife
        tsReportDate
      }
      locations {
        latitude
        longitude
        tsLocationDate
      }
      gotoll {
        vehicleId
        action
        status
        createdAt
        actionAt
        updatedAt
      }
      id
      username
      epaVehicleId
      mroId
      participantConnectionId
      createdBy
      updatedBy
      mro {
        username
        id
        vehicleId
        deviceSerialNumber
        deviceImei
        manufacturer
        model
        hardwareVersion
        firmwareVersion
        gpsEnabled
        configurableParameters
        shippedDate
        shippingCarrier
        shippingTrackingNumber
        deliveredDate
        activationDate
        firstReportedDate
        lastReportedDate
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      connection {
        accessToken
        authorizationCode
        externalVehicleId
        refreshToken
        resourceProvider
        id
        username
        vehicleId
        createdAt
        updatedAt
        forceRestoreConnection
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVehicles = /* GraphQL */ `
  query ListVehicles(
    $username: String
    $id: ModelIDKeyConditionInput
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVehicles(
      username: $username
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        vin
        make
        model
        year
        type
        licensePlate
        registrationState
        isPrimary
        mroType
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        logs
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCordonsByState = /* GraphQL */ `
  query GetCordonsByState(
    $state: String
    $sortDirection: ModelSortDirection
    $filter: ModelCordonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCordonsByState(
      state: $state
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        state
        name
        description
        entryFeeCents
        exitFeeCents
        centsPerMileageUnit
        days
        internalStart
        internalEnd
        minimumDistanceInMileageUnit
        minimumTotalDistanceInMileageUnit
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEPAVehiclesByMakeByYear = /* GraphQL */ `
  query GetEPAVehiclesByMakeByYear(
    $make: String
    $year: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEpaVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEPAVehiclesByMakeByYear(
      make: $make
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        make
        model
        year
        cylinders
        trany
        fuelType
        fuelType1
        fuelType2
        mpgCombined
        mpgCity
        mpgHighway
        kplCombined
        kplCity
        kplHighway
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventsByUserByTimestamp = /* GraphQL */ `
  query GetEventsByUserByTimestamp(
    $username: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEventsByUserByTimestamp(
      username: $username
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        key
        timestamp
        username
        updatedBy
        eventId
        eventName
        note
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMroEventsByMroDeviceByDateTime = /* GraphQL */ `
  query GetMroEventsByMroDeviceByDateTime(
    $mroDeviceSerialNumber: String
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMROEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMroEventsByMroDeviceByDateTime(
      mroDeviceSerialNumber: $mroDeviceSerialNumber
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mroDeviceSerialNumber
        username
        vehiceId
        code
        odometerReadingStart
        odometerReading
        mileage
        flagged
        tripId
        type
        description
        dateTime
        source
        isProcessed
        processLogs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMroEventsByUsernameByFlag = /* GraphQL */ `
  query GetMroEventsByUsernameByFlag(
    $username: String
    $flagged: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMROEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMroEventsByUsernameByFlag(
      username: $username
      flagged: $flagged
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mroDeviceSerialNumber
        username
        vehiceId
        code
        odometerReadingStart
        odometerReading
        mileage
        flagged
        tripId
        type
        description
        dateTime
        source
        isProcessed
        processLogs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMroEventsByTripByDateTime = /* GraphQL */ `
  query GetMroEventsByTripByDateTime(
    $tripId: String
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMROEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMroEventsByTripByDateTime(
      tripId: $tripId
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mroDeviceSerialNumber
        username
        vehiceId
        code
        odometerReadingStart
        odometerReading
        mileage
        flagged
        tripId
        type
        description
        dateTime
        source
        isProcessed
        processLogs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMroEventsByCodeByDateTime = /* GraphQL */ `
  query GetMroEventsByCodeByDateTime(
    $code: String
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMROEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMroEventsByCodeByDateTime(
      code: $code
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mroDeviceSerialNumber
        username
        vehiceId
        code
        odometerReadingStart
        odometerReading
        mileage
        flagged
        tripId
        type
        description
        dateTime
        source
        isProcessed
        processLogs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMroEventsByIsProcessedByDateTime = /* GraphQL */ `
  query GetMroEventsByIsProcessedByDateTime(
    $isProcessed: Int
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMROEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMroEventsByIsProcessedByDateTime(
      isProcessed: $isProcessed
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mroDeviceSerialNumber
        username
        vehiceId
        code
        odometerReadingStart
        odometerReading
        mileage
        flagged
        tripId
        type
        description
        dateTime
        source
        isProcessed
        processLogs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMileageReportsByAuditStatus = /* GraphQL */ `
  query GetMileageReportsByAuditStatus(
    $auditStatus: AuditStatus
    $sortDirection: ModelSortDirection
    $filter: ModelMileageReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMileageReportsByAuditStatus(
      auditStatus: $auditStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        auditStatus
        id
        odoMileage
        reason
        tsReportDate
        updatedBy
        username
        vehicleId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMrosByDeviceSerialNumber = /* GraphQL */ `
  query GetMrosByDeviceSerialNumber(
    $deviceSerialNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelMileageReportingOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMrosByDeviceSerialNumber(
      deviceSerialNumber: $deviceSerialNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        username
        id
        vehicleId
        deviceSerialNumber
        deviceImei
        manufacturer
        model
        hardwareVersion
        firmwareVersion
        gpsEnabled
        configurableParameters
        shippedDate
        shippingCarrier
        shippingTrackingNumber
        deliveredDate
        activationDate
        firstReportedDate
        lastReportedDate
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotificationsByStatusByShouldBeSentAt = /* GraphQL */ `
  query GetNotificationsByStatusByShouldBeSentAt(
    $status: NotificationStatus
    $shouldBeSentAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByStatusByShouldBeSentAt(
      status: $status
      shouldBeSentAt: $shouldBeSentAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        type
        templateName
        email
        subject
        text
        html
        shouldBeSentAt
        retries
        status
        statusMsg
        sentAt
        createdBy
        createdAt
        logs
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotificationsByUsernameByCreatedAt = /* GraphQL */ `
  query GetNotificationsByUsernameByCreatedAt(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByUsernameByCreatedAt(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        type
        templateName
        email
        subject
        text
        html
        shouldBeSentAt
        retries
        status
        statusMsg
        sentAt
        createdBy
        createdAt
        logs
        updatedAt
      }
      nextToken
    }
  }
`;
export const getParticipantsByPilotProgram = /* GraphQL */ `
  query GetParticipantsByPilotProgram(
    $participantPilotProgramId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getParticipantsByPilotProgram(
      participantPilotProgramId: $participantPilotProgramId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getParticipantsByStatus = /* GraphQL */ `
  query GetParticipantsByStatus(
    $status: Status
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getParticipantsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ageGroup
        closedDate
        closedReason
        closedBy
        createdBy
        email
        firstName
        gender
        governmentAffiliation
        isVIP
        lastName
        middleName
        mroDataProviderPreference
        mroDevicePreference
        mroManualCapture
        onboardedDate
        participantPilotProgramId
        phoneNumber
        preferredContactType
        roadChargeCreditCents
        status
        sumFuelTaxCreditCents
        sumMileageUserFeeCents
        updatedBy
        username
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInquiriesByUsernameByStatus = /* GraphQL */ `
  query GetInquiriesByUsernameByStatus(
    $username: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantInquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInquiriesByUsernameByStatus(
      username: $username
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        createdAt
        createdBy
        updatedAt
        updatedBy
        assignee
        category
        subject
        body
        status
        name
        email
        emailOriginalMessageId
        emailS3Key
        bodyHtml
      }
      nextToken
    }
  }
`;
export const getInquiriesByStatusByCreatedAt = /* GraphQL */ `
  query GetInquiriesByStatusByCreatedAt(
    $status: InquiryStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantInquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInquiriesByStatusByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        createdAt
        createdBy
        updatedAt
        updatedBy
        assignee
        category
        subject
        body
        status
        name
        email
        emailOriginalMessageId
        emailS3Key
        bodyHtml
      }
      nextToken
    }
  }
`;
export const getInquiriesByEmailByMessageId = /* GraphQL */ `
  query GetInquiriesByEmailByMessageId(
    $email: String
    $emailOriginalMessageId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantInquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInquiriesByEmailByMessageId(
      email: $email
      emailOriginalMessageId: $emailOriginalMessageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        createdAt
        createdBy
        updatedAt
        updatedBy
        assignee
        category
        subject
        body
        status
        name
        email
        emailOriginalMessageId
        emailS3Key
        bodyHtml
      }
      nextToken
    }
  }
`;
export const getParticipantStatementsByParticipantByMonth = /* GraphQL */ `
  query GetParticipantStatementsByParticipantByMonth(
    $username: String
    $month: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getParticipantStatementsByParticipantByMonth(
      username: $username
      month: $month
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        month
        periodFrom
        periodTo
        filename
        mileage
        fuel
        mbufFee
        cordonFee
        todFee
        tollFee
        fuelTaxCredit
        balance
        trips
        note
        createdBy
        shouldSendEmailToParticipant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getParticipantStatementsByMonth = /* GraphQL */ `
  query GetParticipantStatementsByMonth(
    $month: String
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getParticipantStatementsByMonth(
      month: $month
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        month
        periodFrom
        periodTo
        filename
        mileage
        fuel
        mbufFee
        cordonFee
        todFee
        tollFee
        fuelTaxCredit
        balance
        trips
        note
        createdBy
        shouldSendEmailToParticipant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTransactionsByUsernameByCreatedAt = /* GraphQL */ `
  query GetTransactionsByUsernameByCreatedAt(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTransactionsByUsernameByCreatedAt(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        createdAt
        createdBy
        updatedAt
        description
        type
        status
        retries
        subtotalCents
        discountCents
        amountCents
        paymentMethodId
      }
      nextToken
    }
  }
`;
export const getTransactionsByStatusByCreatedAt = /* GraphQL */ `
  query GetTransactionsByStatusByCreatedAt(
    $status: TransactionStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTransactionsByStatusByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        createdAt
        createdBy
        updatedAt
        description
        type
        status
        retries
        subtotalCents
        discountCents
        amountCents
        paymentMethodId
      }
      nextToken
    }
  }
`;
export const getTransactionsByTypeByCreatedAt = /* GraphQL */ `
  query GetTransactionsByTypeByCreatedAt(
    $type: TransactionType
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTransactionsByTypeByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        createdAt
        createdBy
        updatedAt
        description
        type
        status
        retries
        subtotalCents
        discountCents
        amountCents
      }
      nextToken
    }
  }
`;
export const getTripsByUsernameByCreatedAt = /* GraphQL */ `
  query GetTripsByUsernameByCreatedAt(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripsByUsernameByCreatedAt(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordId
        recordTripId
        mroDeviceSerialNumber
        vehicleVin
        tsStart
        tsEnd
        polyline
        distMro
        odoMro
        distGps
        distRoute
        fuel
        odoGps
        odoMroStart
        odoMroEnd
        fuelStart
        fuelEnd
        fuelAdded
        totalFuel
        username
        vehicleId
        pilotProgramId
        mroId
        createdAt
        processStatus
        processMsg
        processedAt
        logs
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        reviewStatus
        reviewReason
        notes
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripsByVehicleIdByTsEnd = /* GraphQL */ `
  query GetTripsByVehicleIdByTsEnd(
    $vehicleId: ID
    $tsEnd: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripsByVehicleIdByTsEnd(
      vehicleId: $vehicleId
      tsEnd: $tsEnd
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordId
        recordTripId
        mroDeviceSerialNumber
        vehicleVin
        tsStart
        tsEnd
        polyline
        distMro
        odoMro
        distGps
        distRoute
        fuel
        odoGps
        odoMroStart
        odoMroEnd
        fuelStart
        fuelEnd
        fuelAdded
        totalFuel
        username
        vehicleId
        pilotProgramId
        mroId
        createdAt
        processStatus
        processMsg
        processedAt
        logs
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        reviewStatus
        reviewReason
        notes
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripsByMroDeviceSerialNumberByCreeatedAt = /* GraphQL */ `
  query GetTripsByMroDeviceSerialNumberByCreeatedAt(
    $mroDeviceSerialNumber: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripsByMroDeviceSerialNumberByCreeatedAt(
      mroDeviceSerialNumber: $mroDeviceSerialNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordId
        recordTripId
        mroDeviceSerialNumber
        vehicleVin
        tsStart
        tsEnd
        polyline
        distMro
        odoMro
        distGps
        distRoute
        fuel
        odoGps
        odoMroStart
        odoMroEnd
        fuelStart
        fuelEnd
        fuelAdded
        totalFuel
        username
        vehicleId
        pilotProgramId
        mroId
        createdAt
        processStatus
        processMsg
        processedAt
        logs
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        reviewStatus
        reviewReason
        notes
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripsByProcessStatusByCreatedAt = /* GraphQL */ `
  query GetTripsByProcessStatusByCreatedAt(
    $processStatus: TripProcessStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripsByProcessStatusByCreatedAt(
      processStatus: $processStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordId
        recordTripId
        mroDeviceSerialNumber
        vehicleVin
        tsStart
        tsEnd
        polyline
        distMro
        odoMro
        distGps
        distRoute
        fuel
        odoGps
        odoMroStart
        odoMroEnd
        fuelStart
        fuelEnd
        fuelAdded
        totalFuel
        username
        vehicleId
        pilotProgramId
        mroId
        createdAt
        processStatus
        processMsg
        processedAt
        logs
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        reviewStatus
        reviewReason
        notes
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripsByRecordTripId = /* GraphQL */ `
  query GetTripsByRecordTripId(
    $recordTripId: String
    $sortDirection: ModelSortDirection
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripsByRecordTripId(
      recordTripId: $recordTripId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordId
        recordTripId
        mroDeviceSerialNumber
        vehicleVin
        tsStart
        tsEnd
        polyline
        distMro
        odoMro
        distGps
        distRoute
        fuel
        odoGps
        odoMroStart
        odoMroEnd
        fuelStart
        fuelEnd
        fuelAdded
        totalFuel
        username
        vehicleId
        pilotProgramId
        mroId
        createdAt
        processStatus
        processMsg
        processedAt
        logs
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        reviewStatus
        reviewReason
        notes
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripsByReviewStatusByCreatedAt = /* GraphQL */ `
  query GetTripsByReviewStatusByCreatedAt(
    $reviewStatus: TripReviewStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripsByReviewStatusByCreatedAt(
      reviewStatus: $reviewStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordId
        recordTripId
        mroDeviceSerialNumber
        vehicleVin
        tsStart
        tsEnd
        polyline
        distMro
        odoMro
        distGps
        distRoute
        fuel
        odoGps
        odoMroStart
        odoMroEnd
        fuelStart
        fuelEnd
        fuelAdded
        totalFuel
        username
        vehicleId
        pilotProgramId
        mroId
        createdAt
        processStatus
        processMsg
        processedAt
        logs
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        reviewStatus
        reviewReason
        notes
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripAdjustmnentsByParticipantByCreatedAt = /* GraphQL */ `
  query GetTripAdjustmnentsByParticipantByCreatedAt(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripAdjustmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripAdjustmnentsByParticipantByCreatedAt(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tripId
        id
        username
        vehicleId
        tripSegmentId
        type
        state
        stateCode
        cordonId
        cordonName
        todId
        todName
        tollId
        adjMileage
        adjMileageFeeCents
        adjFuel
        adjFuelFeeCents
        adjCordonFeeCents
        adjTodFeeCents
        adjTollFeeCents
        note
        content
        createdBy
        createdAt
        transactionId
        paymentStatus
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripAdjustmentsByTransactionByPaymentStatus = /* GraphQL */ `
  query GetTripAdjustmentsByTransactionByPaymentStatus(
    $transactionId: ID
    $paymentStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripAdjustmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripAdjustmentsByTransactionByPaymentStatus(
      transactionId: $transactionId
      paymentStatus: $paymentStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tripId
        id
        username
        vehicleId
        tripSegmentId
        type
        state
        stateCode
        cordonId
        cordonName
        todId
        todName
        tollId
        adjMileage
        adjMileageFeeCents
        adjFuel
        adjFuelFeeCents
        adjCordonFeeCents
        adjTodFeeCents
        adjTollFeeCents
        note
        content
        createdBy
        createdAt
        transactionId
        paymentStatus
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripAdjustmentsByPaymentStatusByCreatedAt = /* GraphQL */ `
  query GetTripAdjustmentsByPaymentStatusByCreatedAt(
    $paymentStatus: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripAdjustmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripAdjustmentsByPaymentStatusByCreatedAt(
      paymentStatus: $paymentStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tripId
        id
        username
        vehicleId
        tripSegmentId
        type
        state
        stateCode
        cordonId
        cordonName
        todId
        todName
        tollId
        adjMileage
        adjMileageFeeCents
        adjFuel
        adjFuelFeeCents
        adjCordonFeeCents
        adjTodFeeCents
        adjTollFeeCents
        note
        content
        createdBy
        createdAt
        transactionId
        paymentStatus
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripAdjustmentsByTripSegmentIdByCreatedAt = /* GraphQL */ `
  query GetTripAdjustmentsByTripSegmentIdByCreatedAt(
    $tripSegmentId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripAdjustmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripAdjustmentsByTripSegmentIdByCreatedAt(
      tripSegmentId: $tripSegmentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tripId
        id
        username
        vehicleId
        tripSegmentId
        type
        state
        stateCode
        cordonId
        cordonName
        todId
        todName
        tollId
        adjMileage
        adjMileageFeeCents
        adjFuel
        adjFuelFeeCents
        adjCordonFeeCents
        adjTodFeeCents
        adjTollFeeCents
        note
        content
        createdBy
        createdAt
        transactionId
        paymentStatus
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripSegmentsByPaymentStatusByCreatedAt = /* GraphQL */ `
  query GetTripSegmentsByPaymentStatusByCreatedAt(
    $paymentStatus: TripSegmentPaymentStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripSegmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripSegmentsByPaymentStatusByCreatedAt(
      paymentStatus: $paymentStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tripId
        transactionId
        username
        vehicleId
        createdAt
        tsStart
        tsEnd
        type
        state
        stateCode
        cordonId
        cordonName
        cordonEnterTime
        cordonEnterTimeZone
        cordonEnterFeeCents
        todId
        tollTxId
        tollId
        tollEntryId
        tollExitId
        tollEntryTime
        tollExitTime
        polylines
        calculatedMileage
        percentage
        mileage
        fuel
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        tripBinIds
        paymentStatus
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripSegmentsByTollTxId = /* GraphQL */ `
  query GetTripSegmentsByTollTxId(
    $tollTxId: String
    $sortDirection: ModelSortDirection
    $filter: ModelTripSegmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripSegmentsByTollTxId(
      tollTxId: $tollTxId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tripId
        transactionId
        username
        vehicleId
        createdAt
        tsStart
        tsEnd
        type
        state
        stateCode
        cordonId
        cordonName
        cordonEnterTime
        cordonEnterTimeZone
        cordonEnterFeeCents
        todId
        tollTxId
        tollId
        tollEntryId
        tollExitId
        tollEntryTime
        tollExitTime
        polylines
        calculatedMileage
        percentage
        mileage
        fuel
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        tripBinIds
        paymentStatus
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripSegmentsByTransaction = /* GraphQL */ `
  query GetTripSegmentsByTransaction(
    $transactionId: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripSegmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripSegmentsByTransaction(
      transactionId: $transactionId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tripId
        transactionId
        username
        vehicleId
        createdAt
        tsStart
        tsEnd
        type
        state
        stateCode
        cordonId
        cordonName
        cordonEnterTime
        cordonEnterTimeZone
        cordonEnterFeeCents
        todId
        tollTxId
        tollId
        tollEntryId
        tollExitId
        tollEntryTime
        tollExitTime
        polylines
        calculatedMileage
        percentage
        mileage
        fuel
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        tripBinIds
        paymentStatus
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVehicleByMroId = /* GraphQL */ `
  query GetVehicleByMroId(
    $mroId: String
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getVehicleByMroId(
      mroId: $mroId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        vin
        make
        model
        year
        type
        licensePlate
        registrationState
        isPrimary
        mroType
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        logs
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVehicleByVin = /* GraphQL */ `
  query GetVehicleByVin(
    $vin: String
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getVehicleByVin(
      vin: $vin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        vin
        make
        model
        year
        type
        licensePlate
        registrationState
        isPrimary
        mroType
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        epaVehicleCombinedKpl
        epaVehicleCombinedMpg
        logs
        id
        username
        epaVehicleId
        mroId
        participantConnectionId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSurvey = /* GraphQL */ `
  query GetSurvey($id: ID!) {
    getSurvey(id: $id) {
      id
      surveyId
      questions {
        type
        question
        answer
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSurveys = /* GraphQL */ `
  query ListSurveys(
    $id: ID
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSurveys(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        surveyId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
